import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Node } from 'reactflow';
import GoogleMaps from '../../components/shared/GoogleMaps';
import {
  DEFAULT_LAT_LNG,
  ERROR_FIELD_CLASS,
  GeoThermalFormCategory,
  MAX_LENGTH,
  MESSAGES,
  NUMBER,
} from '../../constants';
import { IComponentProperties, IProerties } from '../../constants/interface';
import { FORMULA_TITLE, LABELS } from '../../constants/labelConstant';
import {
  useGetComponentPropertiesQuery,
  useSaveWorkbenchDataMutation,
} from '../../redux/services/workbenchApis';
import {
  checkToDisableOrNot,
  editIcon,
  formatNumberWithCommas,
  getPropertyValue,
  getWorkbenchPayload,
  hasPermissionToEditParamerters,
  infoLogoTrans,
  keyPressHandle,
  locationLogo,
  parseFormattedNumber,
  threeDotHorizontal,
} from '../../utils';
import { useComponentProperties } from '../../hooks/useComponentProperty';
import { useAppSelector } from '../../redux/hooks';
import { toast } from 'react-toastify';
import { scenarioApis } from '../../redux/services/scenarioApis';
import Select from 'react-select';
import PopoverButton from '../../components/layout/PopoverButton';
import TurbineHeightModal from '../../components/workbench/TurbineHeightModal';
import SchedulingModal from '../../components/workbench/SchedulingModal';
import Draggable from 'react-draggable';

interface ObjectParametersProps {
  showModal: boolean
  closeModalHandler: () => void
  component: Node | object
}

const ObjectParameters = (props: ObjectParametersProps) => {
  const { showModal, closeModalHandler, component } = props;
  const componentData = (component as Node).data;
  const workbenchNodes = useAppSelector((state) => state.workbench.nodes);
  const { permissions } = useAppSelector((state) => state.auth);
  const [componentDataState, setComponentDataState] = useState();
  const { updateNodeProperties, updateComponentName } =
    useComponentProperties();
  const settingsData = useAppSelector((state) => state.WorkbenchSettings);
  const workbenchData = useAppSelector((state) => state.workbench);
  const userPermission = workbenchData.scenarioDetails.permission;
  const { BaselineStatus } = useAppSelector((state) => state.scenarioDetails);
  const { id } = useAppSelector((state) => state.scenarioDetails);
  const { data, isSuccess, isLoading, isError } =
    useGetComponentPropertiesQuery(
      { componentName: componentData.class, id },
      {
        skip: false,
        refetchOnMountOrArgChange: true,
      }
    );
  const [saveWorkbenchData, { isLoading: saveWorkbenchLoading }] =
    useSaveWorkbenchDataMutation();
  const formikRef = useRef<FormikProps<any> | null>(null);
  const [validationSchema, setValidationSchema] = useState<any>(Yup.object({}));
  const [componentProperties, setComponentProperties] = useState<
    IProerties[] | undefined
  >(componentData?.properties);
  const [initialOPValues, setInitialOPvalues] = useState<IComponentProperties>(
    {}
  );
  const [localOPValues, setLocalOPvalues] = useState<IComponentProperties>({});
  const [showGoogleMaps, setShowGoogleMaps] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<IProerties | object>(
    {}
  );
  const [locationLat, setLocationLat] = useState<string>('');
  const [locationLng, setLocationLng] = useState<string>('');
  const [editComponentName, setEditComponentName] = useState(false);
  const [componentName, setComponentName] = useState(
    componentData.componentDisplayName
  );
  const componentInput = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [draggableBounds, setDraggableBounds] = useState<any>({});
  const [ShowTurbineHeightModal, setShowTurbineHeightModal] = useState(false);
  const [ShowSchedulingModal, setShowSchedulingModal] = useState(false);
  const { updateSim2Data } = useComponentProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isScalingChecked, setScalingChecked] = useState(false);
  const [disableFields, setDisableFields] = useState(false);

  // Handled Change parameters permissions
  const canEditParameters = hasPermissionToEditParamerters(
    userPermission ?? '',
    permissions,
    !!BaselineStatus
  );

  useEffect(() => {
    if (isSuccess) {
      const filterData = data.data.filter((ele) => ele.paramType === 'input');
      setComponentProperties(filterData);
      const selectedNode = workbenchNodes.find(
        (node: any) => node.data?.class === data.compoment_name
      );
      const shipObjScalingExist = workbenchNodes.filter(
        (ele: any, index: number) =>
          ele.data.class === 'ship_object' && ele.data.scaling_in === NUMBER.N1
      );
      if (shipObjScalingExist.length > NUMBER.N0) {
        setDisableFields(true);
      } else {
        setDisableFields(false);
      }
      const changedData = { ...data };
      if (selectedNode !== undefined) {
        changedData.node_id = selectedNode.id;
      }
      updateSim2Data(changedData);

      // If Component is a geo thermal object then add validation for min and max values...
      if (componentData?.sensitivityFormCategory === GeoThermalFormCategory) {
        const valSchema = Yup.object(
          filterData.reduce<Record<string, Yup.AnySchema>>((schema, param) => {
            schema[param.formulaTitle] = Yup.number()
              .min(param.Min, `Value must be at least ${param.Min}`)
              .max(param.Max, `Value must be at most ${param.Max}`)
              .required(MESSAGES.REQUIRED);
            return schema;
          }, {})
        );
        setValidationSchema(valSchema);
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      toast.error(MESSAGES.COMPONENT_NOT_EXIST);
      closeModalHandler();
    }
  }, [isError]);

  const handleSelectedProperty = (property: any) => {
    setSelectedProperty(property);
    setShowSchedulingModal(true);
  };

  useEffect(() => {
    const selectedNode = workbenchNodes.find(
      (node: any) => node.id === (component as Node).id
    );
    setComponentDataState(selectedNode?.data);
  }, [workbenchNodes]);

  const setPropertyValue = () => {
    const finalValue: any = {};
    componentProperties?.map((property: IProerties) => {
      // if workbench settings is saved then pick location from settings data...
      if (
        (property.formulaTitle === 'location' ||
          property.formulaTitle === 'abatement_location') &&
        settingsData.latitude
      ) {
        finalValue[property.formulaTitle] =
          componentData[property.formulaTitle] !== DEFAULT_LAT_LNG
            ? componentData[property.formulaTitle]
            : `${settingsData.latitude}, ${settingsData.longitude}`;
      } else {
        const val = componentData[property.formulaTitle];
        finalValue[property.formulaTitle] = !(val === '' || val === undefined)
          ? getPropertyValue(componentData[property.formulaTitle])
          : (property.propertyValue as string);
      }
      return finalValue;
    });
    if (finalValue.scaling_in) {
      setScalingChecked(Number(finalValue.scaling_in) === NUMBER.N1);
    }
    setInitialOPvalues(finalValue);
    setLocalOPvalues(finalValue);
  };

  const updateLocationProp = (newLat: string, newLng: string) => {
    const newLocation = `${Number(newLat)},${Number(newLng)}`;
    const newObj = {
      ...formikRef.current?.values,
      [(selectedLocation as IProerties).formulaTitle]: newLocation,
    };
    setLocalOPvalues(newObj);
    setLocationLat(newLat);
    setLocationLng(newLng);
  };

  const handleSaveMap = () => {
    setInitialOPvalues(localOPValues);
    setShowGoogleMaps(false);
  };

  useEffect(() => {
    if (componentProperties?.length) {
      setPropertyValue();
    }
  }, [componentProperties]);

  const openGoogleMap = (property: IProerties) => {
    setSelectedLocation(property);
    const locationLatLng: any =
      initialOPValues[property.formulaTitle]?.split(',');
    setLocationLat(locationLatLng ? locationLatLng[0] : '30.24');
    setLocationLng(locationLatLng ? locationLatLng[1] : '-97.74');
    setShowGoogleMaps(true);
  };

  const handleFormSubmitt = async (values: any) => {
    const valuesToSet = { ...values, scaling_in: localOPValues.scaling_in };
    updateNodeProperties(
      (component as Node).id,
      valuesToSet,
      componentProperties as IProerties[]
    ).then((updatedNodes) => {
      const data: any = {
        ...workbenchData,
        nodes: updatedNodes,
      };
      const payload = getWorkbenchPayload(data);
      saveWorkbenchData(payload).then((res) => {
        if ('data' in res) {
          toast.success(MESSAGES.PROPERTIES_SAVED);
          closeModalHandler();
          scenarioApis.util.invalidateTags(['GetScenarioDetails']);
        } else {
          toast.error(MESSAGES.SOMETHING_WENT_WRONG);
        }
      });
    });
  };

  const groupPropertiesByGroupName = (properties: IProerties[]) => {
    return properties.reduce((acc, property) => {
      const groupName = property.group_name || 'Other';
      if (
        groupName === 'location' &&
        property.propertyName === 'Abatement Location'
      ) {
        return acc;
      }
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(property);
      return acc;
    }, {} as Record<string, IProerties[]>);
  };

  // function to handle location changes on manually setting lat and lng on google maps
  const updateManualLocationChange = (lat: string, lng: string) => {
    lat && setLocationLat(lat);
    lng && setLocationLng(lng);
    updateLocationProp(lat, lng);
  };

  const showEditComponentName = () => {
    setEditComponentName(true);
    setTimeout(() => {
      if (componentInput.current) {
        (componentInput?.current as HTMLElement).focus();
      }
    });
  };

  const updateObjectName = () => {
    setEditComponentName(false);
    updateComponentName((component as Node).id, componentName);
  };

  const updateTurbineHeight = (prop: IProerties, value: string) => {
    const properties = { ...initialOPValues };
    properties[prop.formulaTitle] = value;
    setInitialOPvalues(properties);
    setShowTurbineHeightModal(false);
  };

  const updateSchedule = (
    prop: IProerties,
    value: any,
    scheduleType: string
  ) => {
    const properties = { ...initialOPValues };
    properties[prop.formulaTitle] = { scheduleType, scheduleData: value };
    updateNodeProperties(
      (component as Node).id,
      properties,
      componentProperties as IProerties[]
    );
    setInitialOPvalues(properties);
    setShowSchedulingModal(false);
  };

  useEffect(() => {
    if (containerRef && modalRef && isSuccess) {
      const offset = NUMBER.N100;
      setTimeout(() => {
        const modalWidth = (modalRef.current as HTMLDivElement)?.clientWidth;
        const modalHeight = (modalRef.current as HTMLDivElement)?.clientHeight;
        const containerEle = containerRef.current as HTMLDivElement;
        const { right, bottom } = containerEle?.getBoundingClientRect();
        setDraggableBounds({
          left: -right + offset + NUMBER.N50,
          top: -modalHeight + offset,
          right: modalWidth - offset,
          bottom: bottom - offset,
        });
      }, NUMBER.N1000);
    }
  }, [isSuccess]);

  const changeScalingValue = (value: number | string) => {
    const newObj = { ...formikRef.current?.values, scaling_in: value };
    setLocalOPvalues(newObj);
    setScalingChecked(!isScalingChecked);
  };

  const renderShipScalingToggleButton = (
    value: string | number | undefined
  ) => {
    return (
      <div className='switch-year-btn ship-obj-toggle'>
        <Form>
          <div className='ai-chat-switch-wrap'>
            <span
              className={`switch-btn-label ${
                !isScalingChecked ? 'active' : ''
              }`}
            >
              OFF
            </span>
            <Form.Check
              type='switch'
              id='custom-switch'
              label=''
              onChange={(event) => {
                changeScalingValue(
                  event.target.checked ? NUMBER.N1 : NUMBER.N0
                );
              }}
              checked={isScalingChecked}
            />
            <span
              className={`switch-btn-label switch-btn-label-right ${
                isScalingChecked ? 'active' : ''
              }`}
            >
              ON
            </span>
          </div>
        </Form>
      </div>
    );
  };

  return ReactDOM.createPortal(
    <>
      <div
        className='fade parameter-modal-backdrop modal-backdrop'
        onClick={() => closeModalHandler()}
      ></div>
      <div
        className='containerDiv'
        ref={containerRef}
        style={{ width: '100vw', height: '100vh', position: 'fixed' }}
        onClick={() => closeModalHandler()}
      ></div>
      <Draggable
        handle='.cursor-move'
        bounds={draggableBounds}
        enableUserSelectHack={false}
        cancel='input, button, label'
      >
        <div className='forget-modal setting-modal object-parameters-modal'>
          <div
            className='modal-dialog modal-503 top-right-modal'
            ref={modalRef}
          >
            <div className='modal-content cursor-move'>
              <Modal.Body>
                <Modal.Header className=''>
                  <button
                    type='button'
                    className='close-btn info-logo'
                    aria-label='Close'
                  >
                    <img src={infoLogoTrans} alt='img logo' />
                  </button>
                  <button
                    type='button'
                    className='btn-close'
                    aria-label='Close'
                    onClick={() => closeModalHandler()}
                  ></button>
                </Modal.Header>
                {!editComponentName && (
                  <Modal.Title className='modal-head modal-head-medium component-name'>
                    {componentName}
                    {canEditParameters && (
                      <Button
                        className='edit-icon-wrap btn-no-outline'
                        onClick={showEditComponentName}
                      >
                        <img
                          src={editIcon}
                          alt='edit icon'
                          className='edit-icon'
                        />
                      </Button>
                    )}
                  </Modal.Title>
                )}

                {editComponentName && (
                  <div className='edit-component-name'>
                    <Form.Control
                      type='text'
                      name='component'
                      className='component-input'
                      maxLength={MAX_LENGTH.company_name}
                      defaultValue={componentName}
                      onChange={(event) => setComponentName(event.target.value)}
                      onBlur={updateObjectName}
                      ref={componentInput}
                    />
                  </div>
                )}

                <div className='dsider-tab-wrapper'>
                  <Tabs
                    defaultActiveKey='parameters'
                    id='uncontrolled-tab-example'
                    className=''
                  >
                    <Tab eventKey='parameters' title='Parameters'>
                      <div className='tab-main-container'>
                        <div className='operational-main-wrapper'>
                          {isLoading && (
                            <div className='spinner-wrap'>
                              <Spinner />
                            </div>
                          )}
                          {Object.keys(initialOPValues).length > 0 && (
                            <Formik
                              innerRef={formikRef}
                              initialValues={initialOPValues}
                              validationSchema={validationSchema}
                              enableReinitialize={true}
                              onSubmit={(values) => {
                                handleFormSubmitt(values);
                              }}
                            >
                              {({
                                values,
                                errors,
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                submitCount,
                              }) => {
                                return (
                                  <Form className='' onSubmit={handleSubmit}>
                                    <>
                                      <div
                                        className={`object-form-wrap ${
                                          canEditParameters ? '' : 'read-only'
                                        } `}
                                      >
                                        <div className='row'>
                                          <div className='col-md-12'>
                                            {showGoogleMaps && (
                                              <Modal
                                                show={showGoogleMaps}
                                                onHide={() =>
                                                  setShowGoogleMaps(false)
                                                }
                                                dialogClassName='modal-546 top-right-modal modal-dialog-centered'
                                                className='forget-modal setting-modal object-parameters-modal google-map-modal'
                                              >
                                                <Modal.Header
                                                  closeButton
                                                  className=''
                                                  onClick={() =>
                                                    setShowGoogleMaps(false)
                                                  }
                                                >
                                                  <Modal.Title>
                                                    {
                                                      (
                                                        selectedLocation as IProerties
                                                      ).propertyName
                                                    }
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  <GoogleMaps
                                                    lat={locationLat}
                                                    lng={locationLng}
                                                    updateLocationProp={
                                                      updateLocationProp
                                                    }
                                                  />
                                                  <Form className='object-parameter-form'>
                                                    <div className='row'>
                                                      <div className='col-md-6'>
                                                        <Form.Group
                                                          className='form-group'
                                                          controlId='latitude'
                                                        >
                                                          <Form.Label>
                                                            Latitude
                                                          </Form.Label>
                                                          <Form.Control
                                                            type='number'
                                                            value={locationLat}
                                                            onChange={(e) =>
                                                              updateManualLocationChange(
                                                                e.target.value,
                                                                locationLng
                                                              )
                                                            }
                                                            autoComplete='off'
                                                          />
                                                        </Form.Group>
                                                      </div>
                                                      <div className='col-md-6'>
                                                        <Form.Group
                                                          className='form-group'
                                                          controlId='longitude'
                                                        >
                                                          <Form.Label>
                                                            Longitude
                                                          </Form.Label>
                                                          <Form.Control
                                                            type='text'
                                                            value={locationLng}
                                                            onChange={(e) =>
                                                              updateManualLocationChange(
                                                                locationLat,
                                                                e.target.value
                                                              )
                                                            }
                                                            autoComplete='off'
                                                          />
                                                        </Form.Group>
                                                      </div>
                                                    </div>
                                                  </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    className='btn-no-outline'
                                                    onClick={() => {
                                                      setShowGoogleMaps(false);
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                  <Button
                                                    className='primary'
                                                    onClick={handleSaveMap}
                                                  >
                                                    Save Changes
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            )}
                                          </div>
                                          {/* eslint-disable-next-line complexity */}
                                          {componentProperties !== undefined &&
                                            componentProperties.length > 0 &&
                                            Object.entries(
                                              groupPropertiesByGroupName(
                                                componentProperties
                                              )
                                            ).map(
                                              (
                                                [groupName, properties],
                                                index,
                                                array
                                              ) => (
                                                <div key={groupName}>
                                                  <div className='property-group'>
                                                    <h4 className='group-title groupName'>
                                                      {groupName}-
                                                    </h4>
                                                    <div className='row'>
                                                      {properties.map(
                                                        (
                                                          ele: IProerties,
                                                          index
                                                        ) => (
                                                          <div
                                                            className={`${
                                                              properties.length ===
                                                                1 ||
                                                              ele.propertyName ===
                                                                LABELS.LOCATION ||
                                                              ele.propertyName ===
                                                                LABELS.ABATEMENT_LOCATION
                                                                ? 'col-md-12'
                                                                : 'col-md-6'
                                                            }`}
                                                            key={`${ele.propertyName}--${index}`}
                                                          >
                                                            <Form.Group
                                                              className={`form-group groupName ${
                                                                values[
                                                                  ele
                                                                    .formulaTitle
                                                                ] === '' &&
                                                                submitCount >
                                                                  NUMBER.N0 &&
                                                                ERROR_FIELD_CLASS
                                                              }`}
                                                              controlId={
                                                                ele.formulaTitle
                                                              }
                                                            >
                                                              <div
                                                                className={
                                                                  ele.scheduleOn
                                                                    ? 'demand-with-arrows'
                                                                    : 'parameters-dropdown-wrap'
                                                                }
                                                              >
                                                                <div className='tooltip-container'>
                                                                  <Form.Label className='parameter-label '>
                                                                    {
                                                                      ele.propertyName
                                                                    }
                                                                  </Form.Label>
                                                                  {ele.description && (
                                                                    <div className='tooltip-text'>
                                                                      {
                                                                        ele.description
                                                                      }
                                                                    </div>
                                                                  )}
                                                                </div>
                                                                {ele.scheduleOn && (
                                                                  <Button
                                                                    className='btn-no-outline btn btn-primary'
                                                                    onClick={() =>
                                                                      handleSelectedProperty(
                                                                        ele
                                                                      )
                                                                    }
                                                                    disabled={
                                                                      disableFields &&
                                                                      checkToDisableOrNot(
                                                                        ele.component,
                                                                        ele.formulaTitle
                                                                      )
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={
                                                                        threeDotHorizontal
                                                                      }
                                                                      alt='three dot icon'
                                                                    />
                                                                  </Button>
                                                                )}
                                                                {Array.isArray(
                                                                  componentData[
                                                                    ele
                                                                      .formulaTitle
                                                                  ]
                                                                ) &&
                                                                  !ele.scheduleOn && (
                                                                    <PopoverButton
                                                                      data={
                                                                        componentData[
                                                                          ele
                                                                            .formulaTitle
                                                                        ] as any[]
                                                                      }
                                                                    />
                                                                  )}
                                                              </div>
                                                              {ele.formulaTitle !==
                                                                'scaling_in' && (
                                                                <Form.Control
                                                                  type={'text'}
                                                                  title=''
                                                                  name={
                                                                    ele.formulaTitle
                                                                  }
                                                                  value={
                                                                    ele.propertyType ===
                                                                    'location'
                                                                      ? values[
                                                                          ele
                                                                            .formulaTitle
                                                                        ]
                                                                      : typeof values[
                                                                          ele
                                                                            .formulaTitle
                                                                        ] ===
                                                                        'object'
                                                                      ? formatNumberWithCommas(
                                                                          values[
                                                                            ele
                                                                              .formulaTitle
                                                                          ]
                                                                            ?.scheduleType ||
                                                                            ''
                                                                        )
                                                                      : formatNumberWithCommas(
                                                                          values?.[
                                                                            ele
                                                                              .formulaTitle
                                                                          ]
                                                                        )
                                                                  }
                                                                  onChange={(
                                                                    event
                                                                  ) => {
                                                                    event.target.value =
                                                                      parseFormattedNumber(
                                                                        event
                                                                          .target
                                                                          .value
                                                                      );
                                                                    handleChange(
                                                                      event
                                                                    );
                                                                  }}
                                                                  className={`big-input ${
                                                                    errors[
                                                                      ele
                                                                        .formulaTitle
                                                                    ]
                                                                      ? 'error-field'
                                                                      : ''
                                                                  }`}
                                                                  onKeyDown={
                                                                    keyPressHandle
                                                                  }
                                                                  disabled={
                                                                    (ele.hasDatasource ??
                                                                      false) ||
                                                                    (ele.scheduleOn ??
                                                                      false) ||
                                                                    ((disableFields &&
                                                                      checkToDisableOrNot(
                                                                        ele.component,
                                                                        ele.formulaTitle
                                                                      )) ??
                                                                      false)
                                                                  }
                                                                  readOnly={
                                                                    ele.propertyName ===
                                                                      LABELS.LOCATION ||
                                                                    ele.propertyName ===
                                                                      LABELS.ABATEMENT_LOCATION ||
                                                                    ele.formulaTitle ===
                                                                      FORMULA_TITLE.TURBINE_HEIGHT
                                                                  }
                                                                />
                                                              )}
                                                              {values[
                                                                ele.formulaTitle
                                                              ] === '' &&
                                                                !errors[
                                                                  ele
                                                                    .formulaTitle
                                                                ] &&
                                                                submitCount >
                                                                  NUMBER.N0 && (
                                                                  <span className='error-msg'>{`${ele.propertyName} field is required`}</span>
                                                                )}
                                                              {errors[
                                                                ele.formulaTitle
                                                              ] && (
                                                                <span className='error-msg'>{`${
                                                                  errors[
                                                                    ele
                                                                      .formulaTitle
                                                                  ] as string
                                                                }`}</span>
                                                              )}
                                                              {(ele.propertyName ===
                                                                LABELS.LOCATION ||
                                                                ele.propertyName ===
                                                                  LABELS.ABATEMENT_LOCATION) && (
                                                                <span
                                                                  className='input-logo'
                                                                  onClick={() =>
                                                                    openGoogleMap(
                                                                      ele
                                                                    )
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      locationLogo
                                                                    }
                                                                    alt='logo img'
                                                                  />
                                                                </span>
                                                              )}
                                                              {ele.formulaTitle !==
                                                                FORMULA_TITLE.TURBINE_HEIGHT &&
                                                                ele.formulaTitle !==
                                                                  FORMULA_TITLE.SCHEDULE_DEMAND &&
                                                                ele.propertyName !==
                                                                  LABELS.LOCATION &&
                                                                ele.propertyName !==
                                                                  LABELS.ABATEMENT_LOCATION &&
                                                                ele.unit &&
                                                                ele.formulaTitle !==
                                                                  'scaling_in' && (
                                                                  <div
                                                                    className='custom-select-main capital-cost-select'
                                                                    title={
                                                                      ele.unit
                                                                    }
                                                                  >
                                                                    <div></div>
                                                                    <Select
                                                                      className='custom-select-wrp'
                                                                      classNamePrefix='select'
                                                                      defaultValue={[
                                                                        {
                                                                          value:
                                                                            ele.unit,
                                                                          label:
                                                                            ele.unit,
                                                                          isDisabled:
                                                                            true,
                                                                        },
                                                                      ]}
                                                                      isDisabled={
                                                                        true
                                                                      }
                                                                      isLoading={
                                                                        isLoading
                                                                      }
                                                                      name='color'
                                                                      options={[
                                                                        {
                                                                          value:
                                                                            ele.unit,
                                                                          label:
                                                                            ele.unit,
                                                                          isDisabled:
                                                                            true,
                                                                        },
                                                                      ]}
                                                                    />
                                                                  </div>
                                                                )}
                                                              {ele.formulaTitle ===
                                                                FORMULA_TITLE.TURBINE_HEIGHT && (
                                                                <Button
                                                                  className='btn btn-no-outline turbine-arrow-btn'
                                                                  onClick={() =>
                                                                    setShowTurbineHeightModal(
                                                                      true
                                                                    )
                                                                  }
                                                                ></Button>
                                                              )}
                                                              {ele.component ===
                                                                'ship_object' &&
                                                                ele.formulaTitle ===
                                                                  'scaling_in' &&
                                                                renderShipScalingToggleButton(
                                                                  ele.propertyValue
                                                                )}
                                                            </Form.Group>
                                                            {ShowTurbineHeightModal &&
                                                              ele.formulaTitle ===
                                                                FORMULA_TITLE.TURBINE_HEIGHT && (
                                                                <TurbineHeightModal
                                                                  show={
                                                                    ShowTurbineHeightModal
                                                                  }
                                                                  setShowHide={(
                                                                    action: boolean
                                                                  ) =>
                                                                    setShowTurbineHeightModal(
                                                                      action
                                                                    )
                                                                  }
                                                                  list={
                                                                    ele.list_of_turbines as string[]
                                                                  }
                                                                  defaultValue={
                                                                    values[
                                                                      ele
                                                                        .formulaTitle
                                                                    ] as string
                                                                  }
                                                                  property={ele}
                                                                  onSave={
                                                                    updateTurbineHeight
                                                                  }
                                                                />
                                                              )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                  {/* Add an <hr> tag after each group except the last one */}
                                                  {index < array.length - 1 && (
                                                    <hr className='group-separator' />
                                                  )}
                                                </div>
                                              )
                                            )}
                                          {ShowSchedulingModal && (
                                            <SchedulingModal
                                              show={ShowSchedulingModal}
                                              setShowHide={(action: boolean) =>
                                                setShowSchedulingModal(action)
                                              }
                                              onSave={updateSchedule}
                                              componentData={componentDataState}
                                              selectedProperty={
                                                selectedProperty
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>

                                      <Modal.Footer className='cursor-move'>
                                        <button
                                          type='button'
                                          className='btn-no-outline btn btn-primary'
                                          onClick={() => closeModalHandler()}
                                        >
                                          Cancel
                                        </button>
                                        <Button
                                          className='primary'
                                          type='submit'
                                          disabled={!canEditParameters}
                                        >
                                          {saveWorkbenchLoading ? (
                                            <Spinner />
                                          ) : (
                                            LABELS.SAVE_PROPERTIES
                                          )}
                                        </Button>
                                      </Modal.Footer>
                                    </>
                                  </Form>
                                );
                              }}
                            </Formik>
                          )}
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey='output' title='Output'>
                      <Button className='btn-outline coming-soon-btn'>
                        Coming Soon...
                      </Button>
                    </Tab>
                    <Tab eventKey='methods' title='Methods'>
                      <Button className='btn-outline coming-soon-btn'>
                        Coming Soon...
                      </Button>
                    </Tab>
                  </Tabs>
                </div>
              </Modal.Body>
            </div>
          </div>
        </div>
      </Draggable>
    </>,
    document.body
  );
};

export default ObjectParameters;
