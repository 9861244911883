import React, { useState, useEffect } from 'react';
import {
  CCUS_SOURCES, CCUS_SOURCES_EDGES, MESSAGES, NUMBER, POWER_SOURCES,
  POWER_SOURCES_EDGES, SCENARIO_TYPES, scheduleDataForHydrogenDemand, WIZARD_SCENARIO_VIEWPORT, CCUS_WIZARD_SCENARIO_VIEWPORT
} from '../../constants';
import { CaptureUnitsQuestion, SellToGridQuestion } from './WizardQuestionType';
import { IAnswers, IWizardQuestionRes } from './wizardInterface';
import { Edge, Node } from 'reactflow';
import { Button } from 'react-bootstrap';
import { getWorkbenchURL, gotoWizardLogo, redirectArrow } from '../../utils';
import { useSaveWorkbenchDataMutation } from '../../redux/services/workbenchApis';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { startFullLoading, stopFullLoading } from '../../redux/SpinnerSlice';
import { toast } from 'react-toastify';
import { LocationQuestion } from '../../components/wizard-questions/LocationQuestion';
import { PowerSourcesQuestion } from '../../components/wizard-questions/PowerSourcesQuestion';
import { SourcesCapacityQuestion } from '../../components/wizard-questions/SourcesCapacityQuestion';
import { DemandSupplyQuestion } from '../../components/wizard-questions/DemandSupplyQuestion';
import UserAvatar from '../../components/approval-workflow/UserAvatar';
import { initialSettingsState, updateWorkbenchSettings } from '../../redux/slices/settingsSlice';
import { useSaveLocationSettingsMutation } from '../../redux/services/settingsApis';

interface PowerQuestionListProps {
  wizardQuestions: IWizardQuestionRes[]
  currentQuesttion: number
  lastQuestion: number
  scenarioId: string
  scenarioType: string
  scenarioName: string
  submitQuestion: (nextQuesttionIndex: number) => void
  lastQuestionChange: (questionsLength: number) => void
  ccusType: string | undefined
}


function PowerQuestionList(props: PowerQuestionListProps) {
  const { wizardQuestions, currentQuesttion, lastQuestion, scenarioId, scenarioName, scenarioType, submitQuestion, lastQuestionChange, ccusType } = props;

  const powerDefaultAnswers: IAnswers = {
    location: '',
    powerSources: [],
    hydrogenSources: [],
    powerSourceCapacity: [],
    hydrogenSourceCapacity: [],
    usePowerGrid: null,
    sellToGrid: null,
    toGridCapacity: NUMBER.N0,
    sellFromGrid: null,
    fromGridCapacity: NUMBER.N0,
    supplyDemand: scenarioType === SCENARIO_TYPES.power ? POWER_SOURCES.ElectricPowerDemand.data?.Demand : POWER_SOURCES.HydrogenDemand.data?.hydrogen_schedule,
    // pointSourceCapture ccus
    flueGasSupply: [],
    naturalGasCapacity: 10000,
    captureUnits: 'singleUnit',
    captureUnitsDetail: [],
    liquifyCO2: false,
    co2demandCapacity: NUMBER.N200,
    seqCapacity: NUMBER.N200,
    // directSourceCapture ccus
    towerDetails: [],
    air_speed_schedule: 77,
    regeneration_heating_efficiency: 85,
    regenration_temperature: 60
  };

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { firstName, lastName, image_url } = useAppSelector(state => state.auth);

  const [saveWorkbenchData] = useSaveWorkbenchDataMutation();
  const [saveLocation] = useSaveLocationSettingsMutation();

  const [answersList, setAnswersList] = useState<IAnswers>(powerDefaultAnswers);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [questionsCopy, setQuestionsCopy] = useState<IWizardQuestionRes[]>([]);

  useEffect(() => {
    setQuestionsCopy(wizardQuestions);
  }, [wizardQuestions]);

  const updateLocation = (nodes: Node[]) => {
    return nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          location: answersList.location,
          abatement_location: answersList.location
        }
      };
    });
  };

  const mappingOfComponents = (property: string, projTypeSource: any, projTypeEdge: any, selectedNodes: Node[], nodesEdges: Edge[], componentName?: string) => {
    answersList[property].map((fs: any) => {
      const node = componentName ? projTypeSource[componentName] : projTypeSource[fs.component];
      const edge = componentName === 'flue_gas_supplier' ? projTypeEdge.flue_gas_supplier : projTypeEdge[fs.component];
      node.data[fs.CapacityProperty] = fs.capacity;
      if (fs.component === 'Grid' && property === 'powerSourceCapacity') {
        node.data.P_max_in = answersList.toGridCapacity ?? node.data.P_max_in;
      }
      selectedNodes.push(node);
      componentName !== 'tower' && nodesEdges.push(...edge);
    });
  };

  const getScenarioNodes = () => {
    const selectedNodes: Node[] = [];
    const nodesEdges: Edge[] = [];

    // push selected power sources with their updated capacity values...
    mappingOfComponents('powerSourceCapacity', POWER_SOURCES, POWER_SOURCES_EDGES, selectedNodes, nodesEdges);
    // push selected hydrogen sources with their updated capacity values...
    mappingOfComponents('hydrogenSourceCapacity', POWER_SOURCES, POWER_SOURCES_EDGES, selectedNodes, nodesEdges);
    // // push fuel gas supply with their updated values...
    mappingOfComponents('flueGasSupply', CCUS_SOURCES, CCUS_SOURCES_EDGES, selectedNodes, nodesEdges, 'flue_gas_supplier');
    // // push TOWER  with their updated values...
    mappingOfComponents('towerDetails', CCUS_SOURCES, CCUS_SOURCES_EDGES, selectedNodes, nodesEdges, 'tower');

    // push default nodes and edges which is must to have in workbench...
    const defaultNodes = [];
    const defaultEdges = [];
    if (scenarioType === SCENARIO_TYPES.power) {
      const hasFuelCellStack = answersList.powerSources?.some((source: any) => source.component === 'FuelCellStack');
      const powerDemandValue = answersList.supplyDemand.toString();
      const powerDemand = {
        ...POWER_SOURCES.ElectricPowerDemand,
        data: {
          ...POWER_SOURCES.ElectricPowerDemand.data,
          propertiesValues: {
            ...POWER_SOURCES.ElectricPowerDemand.data.propertiesValues,
            Demand: scheduleDataForHydrogenDemand(powerDemandValue)
          },
          Demand: scheduleDataForHydrogenDemand(powerDemandValue)
        }
      };
      defaultNodes.push(powerDemand, POWER_SOURCES.ElectricityLB);
      if (hasFuelCellStack) {
        defaultNodes.push(POWER_SOURCES.external_hydrogen);
        defaultEdges.push(...POWER_SOURCES_EDGES.external_hydrogen);
      }
      defaultEdges.push(...POWER_SOURCES_EDGES.ElectricPowerDemand);
    } else if (ccusType === 'point_source_capture') {
      const naturalGasNode = CCUS_SOURCES.natural_gas_supplier;
      answersList.captureUnitsDetail.map((cd: any) => {
        const miticoNode = CCUS_SOURCES.mitico;
        const miticoEdge = CCUS_SOURCES_EDGES.mitico;
        const mitico1Node = CCUS_SOURCES.mitico1;
        const mitico1Edge = CCUS_SOURCES_EDGES.mitico1;
        miticoNode.data[cd.CapacityProperty] = cd.capacity;
        mitico1Node.data[cd.CapacityProperty] = cd.capacity;
        if (answersList.captureUnits === 'Double Unit') {
          selectedNodes.push(mitico1Node, miticoNode);
          nodesEdges.push(...mitico1Edge, ...miticoEdge);
        } else {
          selectedNodes.push(miticoNode);
          nodesEdges.push(...miticoEdge);
        }
      });
      if (answersList.liquifyCO2) {
        const liquiNode = CCUS_SOURCES.liquefaction;
        const sequesDemandValue = answersList.seqCapacity.toString();
        const sequesDemand = {
          ...CCUS_SOURCES.sequestration,
          data: {
            ...CCUS_SOURCES.sequestration.data,
            capacity: sequesDemandValue
          }
        };
        defaultNodes.push(liquiNode, sequesDemand);
        defaultEdges.push(...CCUS_SOURCES_EDGES.liquefaction);
      } else {
        const co2DemandValue = answersList.co2demandCapacity.toString();
        const co2Demand = {
          ...CCUS_SOURCES.gaseous_co2_demander,
          data: {
            ...CCUS_SOURCES.gaseous_co2_demander.data,
            co2_demand_schedule: co2DemandValue
          }
        };
        defaultNodes.push(co2Demand);
        defaultEdges.push(...CCUS_SOURCES_EDGES.gaseous_co2_demander);
      }
      naturalGasNode.data.capacity = answersList.naturalGasCapacity;
      defaultNodes.push(naturalGasNode, POWER_SOURCES.ElectricityLB, CCUS_SOURCES.fluegasLB, CCUS_SOURCES.co2LB);
      defaultEdges.push(...CCUS_SOURCES_EDGES.natural_gas_supplier);
    } else if (ccusType === 'direct_air_capture') {
      const dacFanNode = CCUS_SOURCES.dac_fan;
      const regenerationNode = CCUS_SOURCES.regeneration;
      if (answersList.liquifyCO2) {
        const liquiNode = CCUS_SOURCES.liquefaction;
        const sequesDemandValue = answersList.seqCapacity.toString();
        const sequesDemand = {
          ...CCUS_SOURCES.sequestration,
          data: {
            ...CCUS_SOURCES.sequestration.data,
            capacity: sequesDemandValue
          }
        };
        defaultNodes.push(liquiNode, sequesDemand);
        defaultEdges.push(...CCUS_SOURCES_EDGES.liquefaction);
      } else {
        const co2DemandValue = answersList.co2demandCapacity.toString();
        const co2Demand = {
          ...CCUS_SOURCES.gaseous_co2_demander,
          data: {
            ...CCUS_SOURCES.gaseous_co2_demander.data,
            properties: {
              ...CCUS_SOURCES.gaseous_co2_demander.data.properties,
              'Purchase Price ($/kg)': 0.243
            },
            propertiesValues: {
              price: scheduleDataForHydrogenDemand('0.243')
            },
            price: 0.243,
            capacity: co2DemandValue
          }
        };
        defaultNodes.push(co2Demand);
        defaultEdges.push(...CCUS_SOURCES_EDGES.gaseous_co2_demander);
      }
      dacFanNode.data.air_speed_schedule = answersList.air_speed_schedule;
      regenerationNode.data.regeneration_heating_efficiency = answersList.regeneration_heating_efficiency;
      regenerationNode.data.initial_temp = answersList.regenration_temperature;
      defaultNodes.push(dacFanNode, regenerationNode, CCUS_SOURCES.balance_of_plant,
        POWER_SOURCES.ElectricityLB, CCUS_SOURCES.process_pump, CCUS_SOURCES.transfer_pump, CCUS_SOURCES.co2LB);
      defaultEdges.push(...CCUS_SOURCES_EDGES.dac_fan, ...CCUS_SOURCES_EDGES.process_pump, ...CCUS_SOURCES_EDGES.regeneration);
    } else {
      const hydrogenScheduleValue = answersList.supplyDemand.toString();
      const hydrogenDemand = {
        ...POWER_SOURCES.HydrogenDemand,
        data: {
          ...POWER_SOURCES.HydrogenDemand.data,
          propertiesValues: {
            ...POWER_SOURCES.HydrogenDemand.data.propertiesValues,
            hydrogen_schedule: scheduleDataForHydrogenDemand(hydrogenScheduleValue)
          },
          hydrogen_schedule: scheduleDataForHydrogenDemand(hydrogenScheduleValue)
        }
      };
      defaultNodes.push(POWER_SOURCES.ElectricityLB, POWER_SOURCES.LB, POWER_SOURCES.electrolyzer, hydrogenDemand, CCUS_SOURCES.co2LB);
      defaultEdges.push(...POWER_SOURCES_EDGES.electrolyzer, ...POWER_SOURCES_EDGES.HydrogenDemand);
    }

    const updatedNodes = [...selectedNodes, ...defaultNodes];
    const updatedEdges = [...nodesEdges, ...defaultEdges];

    return { updatedNodes, updatedEdges };
  };

  const createWizardScenario = () => {
    const { updatedNodes, updatedEdges } = getScenarioNodes();
    const scenarioNodes = updateLocation(updatedNodes);
    const locSplit = answersList?.location?.split(',');

    const payload = {
      theme: '',
      id: scenarioId,
      name: scenarioName,
      exportJSON: {
        nodes: scenarioNodes,
        edges: updatedEdges,
        viewPort: ccusType === 'point_source_capture' ? CCUS_WIZARD_SCENARIO_VIEWPORT : WIZARD_SCENARIO_VIEWPORT
      }
    };

    dispatch(startFullLoading());
    saveWorkbenchData(payload).then(res => {
      if ('data' in res) {
        toast.success(MESSAGES.WORKBENCH_CREATED);
        saveLocation({
          scenarioId,
          latitude: Number(locSplit[0]),
          longitude: Number(locSplit[1]),
          abatement_latitude: Number(locSplit[0]),
          abatement_longitude: Number(locSplit[1])
        })
          .then((res: any) => {
            dispatch(updateWorkbenchSettings(initialSettingsState));
          });
        dispatch(stopFullLoading());
        navigate(getWorkbenchURL(scenarioId, scenarioType), {
          state: {
            project: location?.state?.project,
            navigateFrom: location?.state?.navigateFrom
          }
        });
      } else {
        dispatch(stopFullLoading());
      }
    });
  };

  const showCapacitySources = (que: IWizardQuestionRes) => {
    return que.type === 'text' && que.mapping.toLowerCase() !== 'demand' && que.mapping !== 'demand schedule';
  };

  const showDemandQuestion = (que: IWizardQuestionRes) => {
    return (que.mapping.toLowerCase() === 'demand' || que.mapping === 'demand schedule' || que.type === 'text-capacity');
  };

  return (
    <>
      {
        questionsCopy?.map((que, index) => {
          const questionIndex = index + NUMBER.N1;
          return (
            currentQuesttion >= questionIndex && <div key={`${que.type}-${index}`}>
              {
                que.type === 'location' && <LocationQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} />
              }
              {
                que.type === 'checkbox' && <PowerSourcesQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} />
              }
              {
                showCapacitySources(que) && <SourcesCapacityQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} />
              }
              {/* Sell To Grid and Sell From Grid */}
              {
                que.type === 'radio-button' && scenarioType !== SCENARIO_TYPES.ccus && <SellToGridQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} gridType={que.mapping === 'to grid' ? 'sellToGrid' : 'sellFromGrid'} />
              }
              {/* CCUS capture units radio button question */}
              {
                que.type === 'radio-button' && scenarioType === SCENARIO_TYPES.ccus && <CaptureUnitsQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  edges={edges} setEdges={setEdges} nodes={nodes} setNodes={setNodes} keyToUpdate={que.mapping}
                  questionsCopy={wizardQuestions} setQuestionsCopy={setQuestionsCopy} setLastQuestion={lastQuestionChange} />
              }
              {/* Power Demand consumption */}
              {
                showDemandQuestion(que) &&
                <DemandSupplyQuestion que={que} index={questionIndex}
                  answersList={answersList} setAnswersList={setAnswersList} submitQuestion={submitQuestion}
                  keyToUpdate={que.mapping} />
              }
            </div>
          );
        })
      }
      {wizardQuestions.length > NUMBER.N0 && <div className="dropdown-wrapper question-list">
        <UserAvatar firstname={firstName} lastname={lastName} imageUrl={image_url} classname={currentQuesttion === NUMBER.N1 ? 'first-question' : ''} />
      </div>}
      {/* Final scenario preview */}
      {currentQuesttion > lastQuestion && <div className="wizard-ques-main">
        <div className="wizard-ques-inner">
          <h2 className='wizard-ques-head'>Based on your inputs, we have created a scenario for you.</h2>
          <div className="message my-message">
            <div className="ai-img-msg">
              <img src={gotoWizardLogo} alt="logo" className="ai-map-img" />

              <Button className="simulation-summary-btn btn btn-primary" onClick={createWizardScenario}>See Workbench
                <img src={redirectArrow} alt="logo" className="redirectArrow-chat" />
              </Button>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default PowerQuestionList;
