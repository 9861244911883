import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IQuestionProps } from './wizardInterface';
import { NUMBER } from '../../constants';
import { Field, Formik } from 'formik';

export const TextElement = ({ que, powerSource, unit, updateValue, defaultValue }: any) => {
  const data = que !== undefined && que.answers.find((item: any) => Object.keys(item)[0] === powerSource.componentDisplayName);
  const powerUnit = typeof data === 'object' ? data[powerSource.componentDisplayName][0] : unit;
  // Debounce function
  const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedHandleChange = debounce(updateValue, NUMBER.N100);

  const onChangeHandler = (value: any, pwrSource: any) => {
    debouncedHandleChange(value, pwrSource);
  };

  if (typeof powerSource === 'object') {
    return (
      <div className='wizard-ques-input rule-input'>
        <span className='wizard-input-des source-name'>{powerSource.componentDisplayName}</span>
        <span className='wizard-input-des rule'>
          <Formik
            initialValues={{ value: powerSource.capacity }}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  type="number"
                  name="value"
                  autoComplete='off'
                  className='wizard-input-text'
                  min={NUMBER.N0}
                  max={NUMBER.N9999999999}
                  onChange={(e: any) => onChangeHandler(e.target.value, powerSource)}
                  component="input"
                />
              </Form>
            )}
          </Formik>
        </span>
        <span className='wizard-input-des'>{powerUnit as string}</span>
      </div>
    );
  } else {
    return (
      <div className='wizard-ques-input rule-input'>
        <span className='wizard-input-des rule'>
          <Form.Control
            type="number"
            autoComplete='off'
            className='wizard-input-text'
            min={NUMBER.N0}
            max={NUMBER.N9999999999}
            defaultValue={defaultValue}
            onChange={(e) => debouncedHandleChange(e.target.value, powerSource)}
          ></Form.Control>
        </span>
        <span className='wizard-input-des'>{powerUnit as string}</span>
      </div>
    );
  }
};

export const CheckboxElement = ({ ans, index, selectPowerSources, isChecked }: any) => {
  return (
    <div className='wizard-ques-input'>
      <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
        <Form.Check
          type="checkbox"
          id={`checkbox-${ans.component}-${index}`}
          label={ans.componentDisplayName}
          value={JSON.stringify(ans)}
          name="companycheckbox"
          checked={isChecked}
          onChange={(e) => selectPowerSources(e.target.value)}
        />
      </div>
    </div>
  );
};

export const RadioElement = ({ ans, index, que, updateValue, defaultVal }: any) => {
  const [isChecked, setIsChecked] = useState(defaultVal ? 'Yes' : defaultVal === false ? 'No' : '');
  const onChange = (value: string) => {
    setIsChecked(value);
    updateValue(value);
  };
  return (
    <div className='wizard-ques-input'>
      <div className="checkbox-wrapper checkbox-grey-wrap checkbox-24">
        <Form.Check
          type="radio"
          id={`${que}-${ans}-${index}`}
          label={ans}
          value={ans}
          name={que}
          defaultChecked={isChecked === ans}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export const QuestionListCard = ({ question }: any) => {
  return (
    <div className="wizard-ques-inner">
      <h2 className='wizard-ques-head'>{question}</h2>
    </div>
  );
};

export const UseGridPowerQuestion = (porps: IQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion } = porps;

  const [powerGrid, setPowerGrid] = useState(answersList.usePowerGrid);

  const updateGridPower = (value: string) => {
    setPowerGrid(value === 'Yes');
  };

  const submitPowerGrid = () => {
    setAnswersList(() => ({
      ...answersList,
      usePowerGrid: powerGrid
    }));
    if (!powerGrid) {
      submitQuestion(index + NUMBER.N3);
    } else {
      submitQuestion(index + NUMBER.N1);
    }
  };

  return (
    <div className="wizard-ques-main" key={`${que.question}-${index}`}>
      <QuestionListCard question={que.question} />
      <div className='wizard-ques-input-wrap'>
        {
          que?.answers?.map((ans, ind) => (
            <RadioElement updateValue={updateGridPower} key={`radio-${ind}`} que={que.question} ans={ans} index={ind} />
          ))
        }
        <Button disabled={powerGrid === null} className='shadow' onClick={() => submitPowerGrid()}>Submit</Button>
      </div>
    </div>
  );
};

export const SellToGridQuestion = (porps: IQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion, gridType } = porps;
  const gridCapacity = gridType === 'sellToGrid' ? 'toGridCapacity' : 'fromGridCapacity';

  const [sellToFromGrid, setSellToFromGrid] = useState(answersList[gridType as string]);

  const updateSellToGrid = (value: string) => {
    setSellToFromGrid(value === 'Yes');
  };

  const submitGridOption = () => {
    setAnswersList(() => ({
      ...answersList,
      [gridType as string]: sellToFromGrid
    }));
    if (!sellToFromGrid) {
      submitQuestion(index + NUMBER.N1);
    } else {
      submitQuestion(index);
    }
  };

  const updateToGridCapacity = (value: string) => {
    setAnswersList(() => ({
      ...answersList,
      [gridCapacity]: +value
    }));
  };

  if (!answersList.usePowerGrid) {
    return <></>;
  }

  return (
    <>
      <div className="wizard-ques-main" key={`${que.question}-${index}`}>
        <QuestionListCard question={que.question} />
        <div className='wizard-ques-input-wrap'>
          {
            que?.answers?.map((ans, ind) => (
              <RadioElement updateValue={updateSellToGrid} key={`radio-${ind}`} que={que.question} ans={ans} index={ind}
                defaultVal={answersList[gridType as string]} />
            ))
          }
          <Button disabled={sellToFromGrid === null} className='shadow' onClick={() => submitGridOption()}>Submit</Button>
        </div>
      </div>
      {answersList[gridType as string] && <>
        <div className="wizard-ques-main">
          <QuestionListCard question={`Define the ${gridType === 'sellToGrid' ? 'sell to' : 'purchase from'} grid capacity`} />
          <div className='wizard-ques-input-wrap'>
            {
              <TextElement defaultValue={answersList[gridCapacity]} updateValue={updateToGridCapacity} powerSource={gridCapacity} unit={'KW'} />
            }
            <Button disabled={!answersList[gridCapacity]} className='shadow' onClick={() => { submitQuestion(index + NUMBER.N1); }}>Submit</Button>
          </div>
        </div>
      </>}
    </>
  );
};

export const CaptureUnitsQuestion = (porps: IQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion, keyToUpdate, questionsCopy, setQuestionsCopy, setLastQuestion } = porps;

  const [valueToUpdate, setValue] = useState(keyToUpdate !== undefined ? answersList[keyToUpdate] : '');

  const updateSellToGrid = (value: string) => {
    if (value === 'Yes') {
      setValue(true);
    } else if (value === 'No') {
      setValue(false);
    } else {
      setValue(value);
    }
  };

  const submitGridOption = () => {
    if (keyToUpdate === 'captureUnits') {
      setAnswersList(() => ({
        ...answersList,
        captureUnits: valueToUpdate
      }));
      submitQuestion(index + NUMBER.N1);
    } else if (keyToUpdate === 'liquifyCO2') {
      setAnswersList(() => ({
        ...answersList,
        liquifyCO2: valueToUpdate
      }));
      if (valueToUpdate) {
        const questions = questionsCopy.filter((ele: any) => ele.mapping !== 'co2demandCapacity');
        setQuestionsCopy !== undefined && setQuestionsCopy(questions);
        setLastQuestion !== undefined && setLastQuestion(questions.length);
      } else {
        const questions = questionsCopy.filter((ele: any) => ele.mapping !== 'seqCapacity');
        setQuestionsCopy !== undefined && setQuestionsCopy(questions);
        setLastQuestion !== undefined && setLastQuestion(questions.length);
      }
      submitQuestion(index + NUMBER.N1);
    }
  };

  return (
    <>
      <div className="wizard-ques-main" key={`${que.question}-${index}`}>
        <QuestionListCard question={que.question} />
        <div className='wizard-ques-input-wrap'>
          {
            que?.answers?.map((ans, ind) => (
              <RadioElement updateValue={updateSellToGrid} key={`radio-${ind}`} que={que.question} ans={ans} index={ind}
                defaultVal={keyToUpdate === 'captureUnits' ? answersList.captureUnits : answersList.liquifyCO2} />
            ))
          }
          <Button className='shadow' onClick={() => submitGridOption()}>Submit</Button>
        </div>
      </div>
    </>
  );
};
