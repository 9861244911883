import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useGetWizardQuestionsQuery } from '../../redux/services/wizardApis';
import { LABELS, NUMBER } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD, SCENARIOS } from '../../utils';
import PowerQuestionList from './PowerQuestionList';
import { IWizardQuestionRes } from './wizardInterface';

function CreateScenarioWithWizard() {
  const searchParams = new URLSearchParams(window.location.search);
  const scenarioId = searchParams.get('scenario_id') ?? '';
  const scenarioName = searchParams.get('scenarioName') ?? '';
  const scenarioType = searchParams.get('type') ?? '';
  const navigate = useNavigate();
  const location = useLocation();
  const questionContainer = useRef<HTMLDivElement>(null);
  const payloadOfWizardApi = {
    projectType: scenarioType,
    scenarioType: location?.state?.scenarioType
  };

  const { data, isSuccess } = useGetWizardQuestionsQuery(payloadOfWizardApi);

  const [wizardQuestions, setQuestions] = useState<IWizardQuestionRes[]>([]);
  const [currentQuesttion, setCurrentQuestion] = useState(NUMBER.N1);
  const [lastQuestion, setLastQuestion] = useState(NUMBER.N2);

  useEffect(() => {
    if (isSuccess) {
      setQuestions(data);
      setLastQuestion(data.length);
    }
  }, [isSuccess]);

  const submitQuestion = (nextQueIndex: number) => {
    moveToNextQuestion(nextQueIndex);
  };

  const moveToNextQuestion = (nextQueIndex: number) => {
    setCurrentQuestion(nextQueIndex);
    setTimeout(() => {
      questionContainer.current && questionContainer.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, NUMBER.N500);
  };

  const goBack = () => {
    const navigateFrom = location?.state?.navigateFrom;
    if (navigateFrom === LABELS.CREATE_SCENARIO || navigateFrom === LABELS.ALL_SCENARIO_LIST) {
      const project = location?.state?.project ?? {};
      navigate(`${SCENARIOS}?projectId=${project._id}&name=${project.name}&projectType=${project.project_type}`);
    } else if (navigateFrom === LABELS.DASHBOARD) {
      navigate(DASHBOARD);
    } else {
      navigate(-NUMBER.N1);
    }
  };

  return (
    <>
      <div className="change-pw-modal create-modal wizard-ques-section">
        <button className='close-btn' onClick={() => goBack()}>
          <span className="icon-close-grey-icon"></span>
        </button>
        <div className='modal-dialog full-width-dialog'>
          <div className='modal-content'>
            <Modal.Body>
              <Modal.Title>{scenarioName}</Modal.Title>
              <p className="modal-title-des">
                Answer a few questions and we will set up a scenario for you.
              </p>
              <div className="create-modal-wrapper">
                <div className="container" ref={questionContainer}>

                  <PowerQuestionList
                    currentQuesttion={currentQuesttion}
                    lastQuestion={lastQuestion}
                    lastQuestionChange={(questionsLength: number) => setLastQuestion(questionsLength)}
                    wizardQuestions={wizardQuestions}
                    submitQuestion={submitQuestion}
                    scenarioId={scenarioId}
                    scenarioType={scenarioType}
                    scenarioName={scenarioName}
                    ccusType={payloadOfWizardApi.scenarioType}
                  />

                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateScenarioWithWizard;
