import { IComponentProperties, IProjectTypeLogo } from './interface';
import { CcusLogo, evGreyLogo, geoThermal, hydrogenLogo, powerLogo } from '../utils/icons';
import { NUMBER } from './numbers';
import { COMPONENT_LABELS } from './labelConstant';
const apiKey = process.env.REACT_APP_MAP_KEY as string;
export const BASE_URL = 'https://djl3iqq9q0.execute-api.us-east-1.amazonaws.com/dev/';
export const tempProjectId = '6532841d1b20c2390c5e5c3d'; // It will removed when we get proper data from Api's
export const EV_CHAT_AI_URL = 'https://chatapi.dsiderenp.app';
export const EV_SIMULATION_URL = 'https://cy1wod4ri3.execute-api.us-east-1.amazonaws.com/dev';
export const SIMULATION_BASE_URL = 'https://aho0y15x65.execute-api.us-east-1.amazonaws.com/';
export const GEO_LOCATION_API_URL = (lat: string | null | number, lng: string | null | number) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
};

export const MAX_LENGTH = {
  company_name: 60, // project_name, scenario_name...
  company_desc: 500,
  image_size: 10485760, // 10 MB
  verify_code: 6,
  input_output_node: 5,
  CHAR_LENGTH_50: 50,
  company_logo_size: 4194304, // 4 MB
  name_length: 25
};

export const WorkbenchLayersLimit = NUMBER.N2;

export const PROJECT_TYPE: Record<string, string> = {
  'hydrogen': 'Green Hydrogen',
  'power': 'Power',
  'Green_Hydrogen': 'Green Hydrogen',
  'Power': 'Power',
  'ev': 'EV Simulation',
  'ccus': 'CCUS',
  'geothermal': COMPONENT_LABELS.GEO_THERMAL,
  'gtherm': 'Gtherm',
  'biofuel': 'Bio Fuel'
};

export const PROJECT_TYPE_LABELS: Record<string, string> = {
  'hydrogen': 'Hydrogen',
  'power': 'Power',
  'ev': 'EV',
  'ccus': 'CCUS',
  'geothermal': COMPONENT_LABELS.GEO_THERMAL,
  'gtherm': 'Gtherm',
  'biofuel': 'Bio Fuel'
};

export const PROJECT_TYPE_LOGO: IProjectTypeLogo = {
  power: powerLogo,
  Power: powerLogo,
  hydrogen: hydrogenLogo,
  Green_Hydrogen: hydrogenLogo,
  ev: evGreyLogo,
  ccus: CcusLogo,
  geothermal: geoThermal,
  gtherm: geoThermal,
  biofuel: hydrogenLogo
};

export const HYDROGEN = 'hydrogen';
export const POWER = 'power';
export const CCUS = 'ccus';
export const GEO_THERMAL = 'geothermal';
export const G_THERM = 'gtherm';
export const EV = 'ev';
export const LB = 'LB';
export const MITICO = 'mitico';
export const GIGADAC = 'dac_fan';

export const GeoThermalFormCategory = 'Geothermal';

export const SCENARIO_TYPES = {
  'green_hydrogen': 'Green_Hydrogen',
  'hydrogen': 'hydrogen',
  'power': 'power',
  'ev': 'ev',
  'ccus': 'ccus',
  'geothermal': 'geothermal',
  'gtherm': 'gtherm'
};


export const ERROR_FIELD_CLASS = 'error-field';

export const PROJECT_FILTER_OPTIONS = [
  {
    label: 'Green Hydrogen Model',
    value: HYDROGEN
  },
  {
    label: 'Power Model',
    value: POWER
  },
  {
    label: 'CCUS',
    value: CCUS
  },
  {
    label: COMPONENT_LABELS.GEO_THERMAL,
    value: GEO_THERMAL
  },
  {
    label: 'GTherm',
    value: G_THERM
  }
];

export const EV_PROJECT_FILTER_OPTION = [
  {
    label: PROJECT_TYPE.ev,
    value: EV
  }
];

export const FIELD_TYPE = {
  NUMBER: 'number',
  TEXT: 'text'
};

export const PROPERTY_FIELD_TYPE: IComponentProperties = {
  'number': 'number',
  'text': 'string'
};

export const STATIC_STRINGS = {
  PASS_DESCRIPTION: 'Password must contain at least eight characters with one uppercase, one lowercase, one numeric, and one special character.'
};

export const STATIC_SET_PASSWORD_STRING = {
  PASS_DESCRIPTION: 'Password must contain at least 8 characters with one uppercase, one lowercase, one numeric, and one special character.',
  LABEL_INPUT: 'Set a new password',
  SET_PASS_DESCRIPTION: 'Please set a new password for your account'
};

export const BUTTON_TITLES = {
  SAVE: 'Save'
};

export const INPUT_FIELD_LABELS = {
  CURRENT_PASS: 'Current Password',
  SET_PASS: 'New Password'
};

export declare enum Position {
  Left = 'left',
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom'
}

export const edgeOptions = {
  type: 'smoothstep',
  style: { stroke: '#7A3BC9' },
  markerEnd: {
    type: 'arrowclosed',
    color: '#7A3BC9'
  }
};

export const EdgesLineType = ['default', 'smoothstep'];

export const WORKBENCH_SCENERIO_IDS = {
  POWER_SCENERIO_ID: '654b5e1a4a078b4614df026b',
  NORMAL_SCENRIO_ID: '639740c12914c971596f0c80'
};

export const CREATE_PROJECT_TITLES = {
  EDIT_DETAILS: 'Edit Project Details',
  CREATE_PROJECT: 'Create a Project',
  CREATE_MESSAGE: 'Add the required details to create a Project.',
  EDIT_MESSAGE: 'You can edit the project details here.'
};

export const connectionLineStyle = {
  strokeWidth: NUMBER.N1,
  stroke: '#7A3BC9'
};

export const LOADER_TITLE = {
  GETTING_READY: 'Getting Ready...',
  LOGGING_OUT: 'Signing out...'
};

export const GRAPH_FORM_TYPE = {
  TEXT_FIELDS: 'text',
  NUMBER_FIELDS: 'number',
  SLIDER_FIELDS: 'slider',
  POWER_HYDROGEN_FIELDS: 'power-hydrogen-fields',
  EV_FIELDS: 'ev-fields'
};

export const SHARED_SCENARIO_PERMISSION = {
  COLLABORATOR: 'collaborator',
  CONSUMER: 'consumer',
  CONSUMER_ROLE: 'Consumer',
  COLLABORATOR_ROLE: 'Collaborator'
};

export const USER_ROLE = {
  EV_USER: 'bpuser',
  COLLABORATOR: 'View',
  CONSUMER: 'user',
  DSIDER_ADMIN: 'admin',
  SUPER_USER: 'companySuperUser'
};

export const DATE_FORMATS = {
  full: 'DD MMM, YYYY hh:mm:ss A',
  medium: 'MMM DD, YYYY',
  short: 'MMM DD'
};

export const commentBoxHeightObj: any = {
  'comment_0': 130,
  'comment_1': 160,
  'comment_max_heght': 245
};
export const NON_EV_GRAPH_COLORS = ['#27859E', '#7189B8', '#DDAB2A'];
export const EV_GRAPH_COLORS = ['#4AA084', '#C76EF3', '#0BA5CE'];
export const VEHICLES_GRAPH_COLORS = ['#27859E', '#4AA084', '#7189B8', '#C76EF3', '#DDAB2A', '#0BA5CE'];
export const POWER_GRAPH_COLORS = ['#17DFEC', '#534FBE', '#67DF7E', '#7189B8', '#C76EF3', '#6EDDCA', '#E3AF49', '#AD3F1B', '#534FBE'];

export const PERMISSION_ORDER = ['admin', 'bpuser', 'companySuperUser', 'user', 'View'];

export const defaultViewport = { x: NUMBER.N0, y: NUMBER.N0, zoom: NUMBER.N1 };

export const Droppable = 'droppable';

export const POWER_CAPACITY = 'Power Capacity';

export const EXPORT_TYPE = 'application/json';

export const EXCEL_EXPORT_TYPE = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

export const THEME_CLASS_NAME = 'light-bg-theme';

export const DEFAULT_LOCATION = { lat: '30.24', lng: '-97.74' };
export const DEFAULT_LAT_LNG = '30.24, -97.74';
export const SIMULATION_SUMMARY = 'Total EV count for the Zip Code 60608 for the current year 2023 is 5,000 and the projected EV growth in the next 26 years is +250%. The Demand Totals on an Avg Weekday is 46.9 KWH, Weekend is 63.7 KWH and on an Avg Week is 72.5 KWH. Visit to any EV Station on an Avg Weekday is 40, Weekend is 73 and on an Avg Week is 54. The POI count in the 5.5 miles radius of the selected Zip code is 713. There are about 750 households in the selected area with the population of 3,950 people.';
export const MIN_MILE = 0;
export const MAX_MILE = 2;
export const SLIDER_STEP = 0.2;
export const MILE_IN_METER = 1609.344;

export const zipCodeDefault = '94103';
export const payloadForSimulation = {
  scenario_id: '6565e4cfbd636ee96bf46511',
  start_year: 2023,
  end_year: 2024,
  lat: 37.7749,
  lng: -122.4194,
  radius: 500
};

export const COMPARE_CHART_CONSTANTS = {
  NON_EV_VEHICLEOF: 'Non EV Vehicles of',
  EV_VEHICLEOF: 'EV Vehicles of',
  DEMAND_TOTAL_OF: 'Demand Total of',
  POINTFORMAT: '{series.name}: {point.y:.2f}',
  chart: {
    type: 'column'
  },
  type: 'datetime'
};

export const CHAT_ROLE_ASSISTANT = 'assistant';
export const CHAT_ROLE_USER = 'user';
export const AADT = 'AADT';
export const POI = 'POI';
export const COMPETITOR_STATION = 'COMPETITOR_STATION';

export const METERS_TO_MILES = 0.000621371;


export const grayStylesMap = [
  {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#e9e9e9'
      },
      {
        'lightness': 17
      }
    ]
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#f5f5f5'
      },
      {
        'lightness': 20
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'lightness': 17
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'lightness': 29
      },
      {
        'weight': 0.2
      }
    ]
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'lightness': 18
      }
    ]
  },
  {
    'featureType': 'road.local',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#ffffff'
      },
      {
        'lightness': 16
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#f5f5f5'
      },
      {
        'lightness': 21
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#dedede'
      },
      {
        'lightness': 21
      }
    ]
  },
  {
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'color': '#ffffff'
      },
      {
        'lightness': 16
      }
    ]
  },
  {
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'saturation': 36
      },
      {
        'color': '#333333'
      },
      {
        'lightness': 40
      }
    ]
  },
  {
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#f2f2f2'
      },
      {
        'lightness': 19
      }
    ]
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#fefefe'
      },
      {
        'lightness': 20
      }
    ]
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'color': '#fefefe'
      },
      {
        'lightness': 17
      },
      {
        'weight': 1.2
      }
    ]
  }
];
export const TWO_MILES_IN_METERS = 3218.69;
export const CREATE_WITH_AI = 'ai';
export const CREATE_WITH_WIZARD = 'wizard';
export const CREATE_FROM_SCRATCH = 'scratch';

export const SCENARIO_STATUS = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
  all_approvers_approved: 'all_approvers_approved'
};

export const totalPowerConsumed = 'Total Power Consumed';
export const totalPowerProduced = 'Total Power Produced';

export const USER_PERMISSIONS = {
  create_projects: 'Create Projects',
  create_scenarios: 'Create Scenarios',
  change_parameters: 'Configure Parameters',
  import_JSON: 'Import JSON file for Scenarios',
  import_excel: 'Import Data for the Parameters',
  simulate_scenario: 'Simulate Data with Default Data',
  simulate_scenario_adding_data: 'Simulated Data by Adding Data',
  run_simulation: 'Run Simulations',
  download_graph_data: 'Download Data',
  download_graph_image: 'Download Image',
  approve_scenarios: 'Approve Scenarios',
  share_scenario: 'Share Scenarios',
  create_with_ai: 'Create with AI',
  create_with_wizard: 'Create with Wizard',
  create_from_scratch: 'Create from Scratch',
  create_user: 'Create User',
  create_object: 'Create Object',
  add_comments: 'Add Comments on the Scenarios',
  disable_object_menu: 'Disable Object Menu'
};

export const genericObject = {
  category: 'Generic',
  component: 'generic',
  componentDisplayName: 'Generic',
  imageUrl: 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/genericdark.svg',
  imageDarkUrl: 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/genericdark.svg',
  powerCategory: 'generic'
};

export const GeoThermalEconomicsModalKeys: Record<string, string[]> = {
  'Reservoir Simulation Results': [
    'Maximum Production Temperature',
    'Production Wellbore Heat Transmission Model',
    'Average Production Temperature',
    'Average Production Well Temperature Drop',
    'Minimum Production Temperature',
    'Average Injection Well Pump Pressure Drop',
    'Initial Production Temperature',
    'Average Production Well Pump Pressure Drop',
    'Average Reservoir Heat Extraction',
    'Average Net Electricity Production'
  ],
  'Capital Costs': [
    'Drilling and completion costs',
    'Field gathering system costs',
    'Drilling and completion costs per well',
    'Total surface equipment costs',
    'Stimulation costs',
    'Exploration costs',
    'Surface power plant costs',
    'Total capital costs'
  ],
  'Operating & Maintenance Costs': [
    'Wellfield maintenance costs',
    'Power plant maintenance costs',
    'Water costs',
    'Total operating and maintenance costs'
  ]
};

export const GeoThermalUnits: Record<string, string> = {
  'degC': '°C',
  'MUSD/yr': '$',
  'MUSD': '$',
  '$/year': '$'
};

export const defaultGeoThermalValues = {
  economic_model: NUMBER.N1,
  inflation_rate_during_construction: NUMBER.N0,
  fixed_charge_rate: NUMBER.N0,
  discount_rate: 0.07,
  noOfYears: NUMBER.N30
};

export const defaultGTherm = {
  LicenseFees: 10,
  PermittingCosts: 10,
  TaxCredits: 1,
  TaxCredit45Q: 0.5,
  DebtInterestPayment: 5,
  DirectorExpenses: 10,
  TotalCOGS: 10,
  DirectExpenses: 10
};

export const GO_TO_EV = 'Go to EV Simulation';
export const GO_TO_WORKBENCH = 'Go to Workbench';

export const sequestrationOptionsForTax = [
  { value: 'tertiary', label: 'Tertiary' },
  { value: 'geological', label: 'Geological' }
];

export const taxCreditTypeOptionsForTax = [
  { value: 'ptc', label: 'PTC' },
  { value: 'cepc', label: 'CEPC' }
];

export const itcBonusTypeOptionsForTax = [
  { value: 'is_domestic_content', label: 'Domestic Content' },
  { value: 'is_energy_community', label: 'Energy Community' }
];

export const SCHEDULE_TYPE = ['Annual', 'Monthly', 'Hourly', 'Constant'];

export const defaultScheduleData = (value: string) => [
  {
    scheduleType: 'Annual',
    scheduleData: [{
      2024: value,
      2025: '0',
      2026: '0',
      2027: '0',
      2028: '0',
      2029: '0',
      2030: '0',
      2031: '0',
      2032: '0',
      2033: '0'
    }]
  },
  {
    scheduleType: 'Monthly',
    scheduleData: [{
      Jan: value,
      Feb: '0',
      Mar: '0',
      Apr: '0',
      May: '0',
      Jun: '0',
      Jul: '0',
      Aug: '0',
      Sep: '0',
      Oct: '0',
      Nov: '0',
      Dec: '0'
    }]
  },
  {
    scheduleType: 'Hourly',
    scheduleData: [{
      '00:00': value,
      '01:00': '0',
      '02:00': '0',
      '03:00': '0',
      '04:00': '0',
      '05:00': '0',
      '06:00': '0',
      '07:00': '0',
      '08:00': '0',
      '09:00': '0',
      '10:00': '0',
      '11:00': '0',
      '12:00': '0',
      '13:00': '0',
      '14:00': '0',
      '15:00': '0',
      '16:00': '0',
      '17:00': '0',
      '18:00': '0',
      '19:00': '0',
      '20:00': '0',
      '21:00': '0',
      '22:00': '0',
      '23:00': '0'
    }]
  },
  {
    scheduleType: 'Constant',
    scheduleData: {
      'constant demand': value
    }
  }
];

export const defaultCapexScheduleData = (value: string) => [{
  scheduleType: 'Annual',
  scheduleData: {
    2024: value,
    2025: '0',
    2026: '0',
    2027: '0',
    2028: '0',
    2029: '0',
    2030: '0',
    2031: '0',
    2032: '0',
    2033: '0'
  }
}];

export const defaultPeriodCostScheduleData = (value: string, constantValue?: string) => [{
  scheduleType: 'Annual',
  scheduleData: {
    2024: value,
    2025: '0',
    2026: '0',
    2027: '0',
    2028: '0',
    2029: '0',
    2030: '0',
    2031: '0',
    2032: '0',
    2033: '0'
  }
}, {
  scheduleType: 'Constant',
  scheduleData: {
    'constant demand': constantValue ?? value
  }
}];

export const scheduleDataForHydrogenDemand = (value: string) => ({
  scheduleType: 'Constant',
  scheduleData: {
    Annual: {
      2024: value,
      2025: '0',
      2026: '0',
      2027: '0',
      2028: '0',
      2029: '0',
      2030: '0',
      2031: '0',
      2032: '0',
      2033: '0'
    },
    Constant: {
      'constant demand': value
    },
    Hourly: {
      '00:00': value,
      '01:00': '0',
      '02:00': '0',
      '03:00': '0',
      '04:00': '0',
      '05:00': '0',
      '06:00': '0',
      '07:00': '0',
      '08:00': '0',
      '09:00': '0',
      '10:00': '0',
      '11:00': '0',
      '12:00': '0',
      '13:00': '0',
      '14:00': '0',
      '15:00': '0',
      '16:00': '0',
      '17:00': '0',
      '18:00': '0',
      '19:00': '0',
      '20:00': '0',
      '21:00': '0',
      '22:00': '0',
      '23:00': '0'
    },
    Monthly: {
      Jan: value,
      Feb: '0',
      Mar: '0',
      Apr: '0',
      May: '0',
      Jun: '0',
      Jul: '0',
      Aug: '0',
      Sep: '0',
      Oct: '0',
      Nov: '0',
      Dec: '0'
    }
  }
});

export const defaultOpexData = (annualValue: string, constantValue: string) => [
  {
    scheduleType: 'Annual',
    scheduleData: [{
      2024: annualValue,
      2025: annualValue,
      2026: annualValue,
      2027: annualValue,
      2028: annualValue,
      2029: annualValue,
      2030: annualValue,
      2031: annualValue,
      2032: annualValue,
      2033: annualValue
    }]
  },
  {
    scheduleType: 'Monthly',
    scheduleData: [{
      Jan: '0',
      Feb: '0',
      Mar: '0',
      Apr: '0',
      May: '0',
      Jun: '0',
      Jul: '0',
      Aug: '0',
      Sep: '0',
      Oct: '0',
      Nov: '0',
      Dec: '0'
    }]
  },
  {
    scheduleType: 'Hourly',
    scheduleData: [{
      '00:00': '0',
      '01:00': '0',
      '02:00': '0',
      '03:00': '0',
      '04:00': '0',
      '05:00': '0',
      '06:00': '0',
      '07:00': '0',
      '08:00': '0',
      '09:00': '0',
      '10:00': '0',
      '11:00': '0',
      '12:00': '0',
      '13:00': '0',
      '14:00': '0',
      '15:00': '0',
      '16:00': '0',
      '17:00': '0',
      '18:00': '0',
      '19:00': '0',
      '20:00': '0',
      '21:00': '0',
      '22:00': '0',
      '23:00': '0'
    }]
  },
  {
    scheduleType: 'Constant',
    scheduleData: {
      'constant demand': constantValue
    }
  }
];

export const SCHEDULE_KEYS: Record<string, any> = {
  'Annual': [2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033],
  'Monthly': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  'Hourly': ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
    '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
  ],
  'Constant': ['constant demand']
};

export const disablePropertiesDueToShipScaling = [{
  'ammonia_supplier': ['flow_rate']
}, {
  'ammonia_storage': ['capacity']
}, {
  'ammonia_cracker': ['max_volumetric_capacity', 'power_capacity']
}, {
  'hydrogen': ['hydrogen_schedule']
}, {
  'external_hydrogen': ['capacity']
}, {
  'hydrogen_storage': ['capacity', 'initial_capacity']
}, {
  'electrolyzer': ['capacity']
}, {
  'hydrogen_supplier': ['flow_rate']
}, {
  'diesel': ['capacity']
}];

export const CCUS_SCENARIO_TYPES = [
  { value: 'point_source_capture', label: 'Point Source Capture', disabled: false },
  { value: 'direct_air_capture', label: 'Direct Air Capture', disabled: false },
  { value: 'blue_hydrogen', label: 'Blue Hydrogen', disabled: true }
];
