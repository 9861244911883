import { Edge, MarkerType, Position } from 'reactflow';
import { IDsiderNode } from './interface';
import { defaultCapexScheduleData, defaultOpexData, defaultPeriodCostScheduleData, defaultScheduleData, scheduleDataForHydrogenDemand } from './static';


export const POWER_SOURCES: Record<string, IDsiderNode> = {
  'turbine': {
    'width': 64,
    'height': 64,
    'id': 'Turbines_4129',
    'name': 'Turbines',
    'type': 'dsiderNode',
    'position': {
      'x': 22.72715719165103,
      'y': 242.4868603668562
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'turbine',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Turbines.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Turbines.svg',
      'componentDisplayName': 'Land Turbines',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'Power_Capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Capital_Cost': 'number',
        'LF': 'number',
        'Period_Cost': 'number',
        'Power_Capacity': 'number',
        'PwrRETransVCost': 'number',
        'takeOfPercent': 'number',
        'TransLoss': 'number',
        'turbineHeight': 'text',
        'PwrREAvail': 'number',
        'PwrREOut': 'number',
        'PwrREVCost': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Capital_Cost': '$/KW',
        'LF': '%',
        'Period_Cost': '$/year/KW',
        'Power_Capacity': 'KW',
        'PwrRETransVCost': '$/KW',
        'takeOfPercent': '%',
        'TransLoss': '%',
        'turbineHeight': 'm',
        'PwrREAvail': 'KW',
        'PwrREOut': 'KW',
        'PwrREVCost': '$/KW'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Capital Cost ($/KW)': 1800,
        'Period Cost ($/year/KW)': 40,
        'Power Capacity (KW)': 6000,
        'PwrRETransVCost ($/KW)': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0.13,
        'Take off Percent (Percentage (%))': 95,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Turbine Model & Height (m)': 'Windflow.500,150',
        'Variable cost of energy ($/KW)': 0
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capital Cost': 'Capital_Cost',
        'Load factor': 'LF',
        'Period Cost': 'Period_Cost',
        'Power Capacity': 'Power_Capacity',
        'PwrRETransVCost': 'PwrRETransVCost',
        'Take off Percent': 'takeOfPercent',
        'Transmission Loss Factor': 'TransLoss',
        'Turbine Model & Height': 'turbineHeight',
        'Turbines Available': 'PwrREAvail',
        'Turbines Produced': 'PwrREOut',
        'Variable cost of energy': 'PwrREVCost'
      },
      'listOfTurbines': [
        'Acciona.AW77.1500',
        'Alstom.Eco.110',
        'Alstom.Eco.74',
        'Alstom.Eco.80',
        'Bonus.B23.150',
        'Bonus.B33.300',
        'Bonus.B37.450',
        'Bonus.B41.500',
        'Bonus.B44.600',
        'Bonus.B54.1000',
        'Bonus.B62.1300',
        'Bonus.B82.2300',
        'Dewind.D4.41.500',
        'Dewind.D6.1000',
        'EWT.DirectWind.52.900',
        'Enercon.E101.3000',
        'Enercon.E112.4500',
        'Enercon.E126.6500',
        'Enercon.E126.7000',
        'Enercon.E126.7500',
        'Enercon.E40.500',
        'Enercon.E40.600',
        'Enercon.E44.900',
        'Enercon.E48.800',
        'Enercon.E53.800',
        'Enercon.E66.1500',
        'Enercon.E66.1800',
        'Enercon.E66.2000',
        'Enercon.E70.2000',
        'Enercon.E70.2300',
        'Enercon.E82.1800',
        'Enercon.E82.2000',
        'Enercon.E82.2300',
        'Enercon.E82.3000',
        'Enercon.E92.2300',
        'Enercon.E92.2350',
        'GE.1.5s',
        'GE.1.5se',
        'GE.1.5sl',
        'GE.1.5sle',
        'GE.1.5xle',
        'GE.1.6',
        'GE.1.7',
        'GE.2.5xl',
        'GE.2.75.103',
        'GE.900S',
        'Gamesa.G128.4500',
        'Gamesa.G47.660',
        'Gamesa.G52.850',
        'Gamesa.G58.850',
        'Gamesa.G80.2000',
        'Gamesa.G87.2000',
        'Gamesa.G90.2000',
        'Goldwind.GW82.1500',
        'NEG.Micon.M1500.500',
        'NEG.Micon.M1500.750',
        'NEG.Micon.NM48.750',
        'NEG.Micon.NM52.900',
        'NEG.Micon.NM60.1000',
        'NEG.Micon.NM64c.1500',
        'NEG.Micon.NM80.2750',
        'Nordex.N100.2500',
        'Nordex.N131.3000',
        'Nordex.N131.3300',
        'Nordex.N27.150',
        'Nordex.N29.250',
        'Nordex.N43.600',
        'Nordex.N50.800',
        'Nordex.N60.1300',
        'Nordex.N80.2500',
        'Nordex.N90.2300',
        'Nordex.N90.2500',
        'Nordtank.NTK500',
        'Nordtank.NTK600',
        'PowerWind.56.900',
        'REPower.MD77.1500',
        'REpower.3.4M',
        'REpower.5M',
        'REpower.6M',
        'REpower.MD70.1500',
        'REpower.MM70.2000',
        'REpower.MM82.2000',
        'REpower.MM92.2000',
        'Siemens.SWT.1.3.62',
        'Siemens.SWT.2.3.101',
        'Siemens.SWT.2.3.82',
        'Siemens.SWT.2.3.93',
        'Siemens.SWT.3.0.101',
        'Siemens.SWT.3.6.107',
        'Siemens.SWT.3.6.120',
        'Siemens.SWT.4.0.130',
        'Suzlon.S88.2100',
        'Suzlon.S97.2100',
        'Tacke.TW600.43',
        'Vestas.V100.1800',
        'Vestas.V100.2000',
        'Vestas.V110.2000',
        'Vestas.V112.3000',
        'Vestas.V112.3300',
        'Vestas.V164.7000',
        'Vestas.V27.225',
        'Vestas.V29.225',
        'Vestas.V39.500',
        'Vestas.V42.600',
        'Vestas.V44.600',
        'Vestas.V47.660',
        'Vestas.V52.850',
        'Vestas.V66.1650',
        'Vestas.V66.1750',
        'Vestas.V66.2000',
        'Vestas.V80.1800',
        'Vestas.V80.2000',
        'Vestas.V90.1800',
        'Vestas.V90.2000',
        'Vestas.V90.3000',
        'Wind.World.W3700',
        'Wind.World.W4200',
        'Windflow.500',
        'Windmaster.WM28.300',
        'Windmaster.WM43.750',
        'XANT.M21.100',
        'Siemens Gamesa132_4988_00',
        'Siemens Gamesa132_3550_01',
        'General Electric158_5500_09',
        'Turbine Lomas GE'
      ],
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('1800'),
        'LF': 0,
        'Period_Cost': defaultPeriodCostScheduleData('40'),
        'Power_Capacity': 6000,
        'PwrRETransVCost': scheduleDataForHydrogenDemand('0'),
        'takeOfPercent': 95,
        'TransLoss': 0,
        'turbineHeight': 'Windflow.500,150',
        'PwrREAvail': 0,
        'PwrREOut': 0,
        'PwrREVCost': 0,
        'comments': [],
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0.13
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Capital_Cost': 1800,
      'LF': 0,
      'Period_Cost': 40,
      'Power_Capacity': 6000,
      'PwrRETransVCost': 0,
      'takeOfPercent': 95,
      'TransLoss': 0,
      'turbineHeight': 'Windflow.500,150',
      'PwrREAvail': 0,
      'PwrREOut': 0,
      'PwrREVCost': 0,
      'comments': [],
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0.13
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 22.72715719165103,
      'y': 242.4868603668562
    },
    'hidden': false
  },
  'solar_panel': {
    'width': 64,
    'height': 64,
    'id': 'Solar_Panel_7328',
    'name': 'Solar Panel',
    'type': 'dsiderNode',
    'position': {
      'x': 24.82448599102372,
      'y': 130.28982743925246
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'Solar',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Solar+Panel.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Solar+Panel.svg',
      'componentDisplayName': 'Solar Panel',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'Power_Capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Capital_Cost': 'number',
        'LF': 'number',
        'Period_Cost': 'number',
        'Power_Capacity': 'number',
        'PwrRETransVCost': 'number',
        'PwrREAvail': 'number',
        'PwrREOut': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'takeOfPercent': 'number',
        'TransLoss': 'number',
        'PwrREVCost': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Capital_Cost': '$/KW',
        'LF': 'Percentage (%)',
        'Period_Cost': '$/year/KW',
        'Power_Capacity': 'KW',
        'PwrRETransVCost': '$/KW',
        'PwrREAvail': 'KW',
        'PwrREOut': 'KW',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e',
        'takeOfPercent': 'Percentage (%)',
        'TransLoss': 'Percentage (%)',
        'PwrREVCost': '$/KW'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 1300,
        'Load factor (Percentage (%))': 0,
        'Period Cost ($/year/KW)': 18,
        'Power Capacity (KW)': 3000,
        'Power Transport cost ($/KW)': 0,
        'Renewable Power Available (KW)': 0,
        'Renewable Power Produced (KW)': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0.43,
        'Take off Percent (Percentage (%))': 80,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Variable cost of energy ($/KW)': 0
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capital Cost': 'Capital_Cost',
        'Load factor': 'LF',
        'Period Cost': 'Period_Cost',
        'Power Capacity': 'Power_Capacity',
        'Power Transport cost': 'PwrRETransVCost',
        'Renewable Power Available': 'PwrREAvail',
        'Renewable Power Produced': 'PwrREOut',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Take off Percent': 'takeOfPercent',
        'Transmission Loss Factor': 'TransLoss',
        'Variable cost of energy': 'PwrREVCost'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('1300'),
        'LF': 0,
        'Period_Cost': defaultPeriodCostScheduleData('18'),
        'Power_Capacity': 3000,
        'PwrRETransVCost': 0.0,
        'PwrREAvail': 0,
        'PwrREOut': 0,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0.43,
        'takeOfPercent': 80,
        'TransLoss': 0,
        'PwrREVCost': scheduleDataForHydrogenDemand('0')
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Capital_Cost': 1300,
      'LF': 0,
      'Period_Cost': 18,
      'Power_Capacity': 3000,
      'PwrRETransVCost': 0.0,
      'PwrREAvail': 0,
      'PwrREOut': 0,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0.43,
      'takeOfPercent': 80,
      'TransLoss': 0,
      'PwrREVCost': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 24.82448599102372,
      'y': 130.28982743925246
    },
    'hidden': false
  },
  'battery': {
    'width': 64,
    'height': 64,
    'id': 'Battery_5278',
    'name': 'Battery',
    'type': 'dsiderNode',
    'position': {
      'x': 454.2564306026136,
      'y': 34.554062721459275
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'battery',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Battery.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Battery.svg',
      'componentDisplayName': 'Battery',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Battery Capacity',
      'capacityProperty': 'BatCapStart',
      'powerCategory': 'mixed',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Battery Capital Cost': 'number',
        'Battery Period Cost': 'number',
        'ChargeVCost': 'number',
        'DischargeVCost': 'number',
        'minimum_charge_proportion': 'number',
        'MaxBatDCharge': 'number',
        'BatCapStart': 'number',
        'PwrBatInitCharge': 'number',
        'PwrBatMinCharge': 'number',
        'LossBatStore': 'number',
        'PwrBatTgtCharge': 'number',
        'LossBatOut': 'number',
        'BatCapDegrade': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Battery Capital Cost': '$/kWh',
        'Battery Period Cost': '$/year/kWh',
        'ChargeVCost': '$/kWh',
        'DischargeVCost': '$/kWh',
        'minimum_charge_proportion': '%',
        'MaxBatDCharge': '%',
        'BatCapStart': 'kWh',
        'PwrBatInitCharge': '%',
        'PwrBatMinCharge': '%',
        'LossBatStore': '%/Month',
        'PwrBatTgtCharge': '%',
        'LossBatOut': '%',
        'BatCapDegrade': '%'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Battery Capital Cost ($/kWh)': 1300,
        'Battery Period Cost ($/year/kWh)': 45,
        'Charge Cost ($/kWh)': 0,
        'Discharge Cost ($/kWh)': 0,
        'Hourly Charge Percent (Percentage (%))': 20,
        'Hourly Discharge Percent (Percentage (%))': 20,
        'Initial Capacity (kWh)': 3000,
        'Initial Charge Percent (Percentage (%))': 80,
        'Minimum Fill Percent (Percentage (%))': 80,
        'Monthly Self Discharge Percent (%/Month)': 0.5,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0.033,
        'Target Fill Percent (Percentage (%))': 80,
        'Transmission Loss Out (Percentage (%))': 0,
        'Yearly Capacity Degrade Factor (Percentage (%))': 1.5
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Battery Capital Cost': 'Battery Capital Cost',
        'Battery Period Cost': 'Battery Period Cost',
        'Charge Cost': 'ChargeVCost',
        'Discharge Cost': 'DischargeVCost',
        'Hourly Charge Percent': 'minimum_charge_proportion',
        'Hourly Discharge Percent': 'MaxBatDCharge',
        'Initial Capacity': 'BatCapStart',
        'Initial Charge Percent': 'PwrBatInitCharge',
        'Minimum Fill Percent': 'PwrBatMinCharge',
        'Monthly Self Discharge Percent': 'LossBatStore',
        'Target Fill Percent': 'PwrBatTgtCharge',
        'Transmission Loss Out': 'LossBatOut',
        'Yearly Capacity Degrade Factor': 'BatCapDegrade'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Battery Capital Cost': defaultCapexScheduleData('1300'),
        'Battery Period Cost': defaultPeriodCostScheduleData('45'),
        'ChargeVCost': scheduleDataForHydrogenDemand('0'),
        'DischargeVCost': scheduleDataForHydrogenDemand('0'),
        'minimum_charge_proportion': 20,
        'MaxBatDCharge': 20,
        'BatCapStart': 3000,
        'PwrBatInitCharge': 80,
        'PwrBatMinCharge': 80,
        'LossBatStore': 0.5,
        'PwrBatTgtCharge': 80,
        'LossBatOut': 0,
        'BatCapDegrade': 1.5,
        'comments': [],
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0.033
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Battery Capital Cost': 1300,
      'Battery Period Cost': 45,
      'ChargeVCost': 0,
      'DischargeVCost': 0,
      'minimum_charge_proportion': 20,
      'MaxBatDCharge': 20,
      'BatCapStart': 3000,
      'PwrBatInitCharge': 80,
      'PwrBatMinCharge': 80,
      'LossBatStore': 0.5,
      'PwrBatTgtCharge': 80,
      'LossBatOut': 0,
      'BatCapDegrade': 1.5,
      'comments': [],
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0.033
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 454.2564306026136,
      'y': 34.554062721459275
    },
    'hidden': false
  },
  'Grid': {
    'width': 64,
    'height': 64,
    'id': 'Grid_8207',
    'name': 'Grid',
    'type': 'dsiderNode',
    'position': {
      'x': 454.16419021804677,
      'y': 425.77096530969374
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'Grid',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Grid.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Grid.svg',
      'componentDisplayName': 'Grid',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Grid Capacity',
      'capacityProperty': [
        'P_max_out, P_max_in'
      ],
      'powerCategory': 'mixed',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Max_Power_Available': 'number',
        'P_max_out': 'number',
        'P_max_in': 'number',
        'Price': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'selling_price': 'number',
        'loss_in': 'number',
        'loss_out': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Max_Power_Available': 'KW',
        'P_max_out': 'KW',
        'P_max_in': 'KW',
        'Price': '$/KW',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e',
        'selling_price': '$/KW',
        'loss_in': 'Percentage (%)',
        'loss_out': 'Percentage (%)'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Max Power Available (KW)': 100000,
        'Max Power From Grid (KW)': 100000,
        'Max Power To Grid (KW)': 0,
        'Purchase Price ($/KW)': 0.10,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Selling Price ($/KW)': 0,
        'loss in (Percentage (%))': 0.01,
        'loss out (Percentage (%))': 0.01
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Max Power Available': 'Max_Power_Available',
        'Max Power From Grid': 'P_max_out',
        'Max Power To Grid': 'P_max_in',
        'Purchase Price': 'Price',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Selling Price': 'selling_price',
        'loss in': 'loss_in',
        'loss out': 'loss_out'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Max_Power_Available': 100000,
        'P_max_out': 100000,
        'P_max_in': 0,
        'Price': scheduleDataForHydrogenDemand('0.10'),
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'selling_price': scheduleDataForHydrogenDemand('0'),
        'loss_in': 0.01,
        'loss_out': 0.01
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Max_Power_Available': 100000,
      'P_max_out': 100000,
      'P_max_in': 0,
      'Price': 0.10,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'selling_price': 0,
      'loss_in': 0.01,
      'loss_out': 0.01,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 454.16419021804677,
      'y': 425.77096530969374
    },
    'hidden': false
  },
  'natural_gas': {
    'width': 64,
    'height': 64,
    'id': 'Natural_Gas_3631',
    'name': 'Natural Gas',
    'type': 'dsiderNode',
    'position': {
      'x': 24.003130147654133,
      'y': 362.62636179709716
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'natural_gas',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/gas.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/gas.svg',
      'componentDisplayName': 'Natural Gas',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'abatement_location': 'text',
        'location': 'text',
        'Capital_Cost': 'number',
        'period_cost': 'number',
        'fuel_price': 'number',
        'capacity': 'number',
        'transmission_loss_proportion': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number'
      },
      'units': {
        'abatement_location': '',
        'location': '',
        'Capital_Cost': '$/KW',
        'period_cost': '$/year/KW',
        'fuel_price': '$/Mcf',
        'capacity': 'KVa',
        'transmission_loss_proportion': 'Percentage (%)',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e'
      },
      'properties': {
        'Abatement Location': '30.24, -97.74',
        'Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 20,
        'Period Cost ($/year/KW)': 20,
        'Fuel Price ($/Mcf)': 3.5,
        'Capacity (KVa)': 100,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0.181,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0
      },
      'formulaTitle': {
        'Abatement Location': 'abatement_location',
        'Location': 'location',
        'Capital Cost': 'Capital_Cost',
        'Period Cost': 'period_cost',
        'Fuel Price': 'fuel_price',
        'Capacity': 'capacity',
        'Transmission Loss Factor': 'transmission_loss_proportion',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex'
      },
      'propertiesValues': {
        'abatement_location': '29.749907, -95.358421',
        'location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('20'),
        'period_cost': defaultPeriodCostScheduleData('20'),
        'fuel_price': scheduleDataForHydrogenDemand('3.5'),
        'capacity': 100,
        'transmission_loss_proportion': 0,
        'scope_1_opex': 0.181,
        'scope_2_opex': 0,
        'scope_3_capex': 0
      },
      'abatement_location': '29.749907, -95.358421',
      'location': '29.749907, -95.358421',
      'Capital_Cost': 20,
      'period_cost': 20,
      'fuel_price': 3.5,
      'capacity': 100,
      'transmission_loss_proportion': 0,
      'scope_1_opex': 0.181,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 24.003130147654133,
      'y': 362.62636179709716
    },
    'hidden': false
  },
  'diesel': {
    'id': 'Diesel_3486',
    'name': 'Diesel',
    'type': 'dsiderNode',
    'position': {
      'x': 23.980578600084414,
      'y': 16.62052049755033
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'diesel',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/diesel.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/diesel.svg',
      'componentDisplayName': 'Diesel',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'abatement_location': 'text',
        'location': 'text',
        'Capital_Cost': 'number',
        'period_cost': 'number',
        'fuel_price': 'number',
        'capacity': 'number',
        'transmission_loss_proportion': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number'
      },
      'units': {
        'abatement_location': '',
        'location': '',
        'Capital_Cost': '$/KW',
        'period_cost': '$/year/KW',
        'fuel_price': '$/gallon',
        'capacity': 'KVa',
        'transmission_loss_proportion': 'Percentage (%)',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e'
      },
      'properties': {
        'Abatement Location': '30.24, -97.74',
        'Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 20,
        'Period Cost ($/year/KW)': 20,
        'Fuel Price ($/gallon)': 3.5,
        'Capacity (KVa)': 100,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0.253,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0
      },
      'formulaTitle': {
        'Abatement Location': 'abatement_location',
        'Location': 'location',
        'Capital Cost': 'Capital_Cost',
        'Period Cost': 'period_cost',
        'Fuel Price': 'fuel_price',
        'Capacity': 'capacity',
        'Transmission Loss Factor': 'transmission_loss_proportion',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex'
      },
      'propertiesValues': {
        'abatement_location': '29.749907, -95.358421',
        'location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('20'),
        'period_cost': defaultPeriodCostScheduleData('20'),
        'fuel_price': scheduleDataForHydrogenDemand('3.5'),
        'capacity': 100,
        'transmission_loss_proportion': 0,
        'scope_1_opex': 0.253,
        'scope_2_opex': 0,
        'scope_3_capex': 0
      },
      'abatement_location': '29.749907, -95.358421',
      'location': '29.749907, -95.358421',
      'Capital_Cost': 20,
      'period_cost': 20,
      'fuel_price': 3.5,
      'capacity': 100,
      'transmission_loss_proportion': 0,
      'scope_1_opex': 0.253,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'comments': []
    },
    'selected': true,
    'dragging': false,
    'width': 64,
    'height': 64,
    'positionAbsolute': {
      'x': 23.980578600084414,
      'y': 16.62052049755033
    }
  },
  'ElectricPowerDemand': {
    'width': 64,
    'height': 64,
    'id': 'Electric_Power_Demand_6385',
    'name': 'Electric Power Demand',
    'type': 'dsiderNode',
    'position': {
      'x': 627.062836580373,
      'y': 244.80372474745263
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'ElectricPowerDemand',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Electric+Power+Demand.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Electric+Power+Demand.svg',
      'componentDisplayName': 'Electric Power Demand',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'Energy Demand',
      'capacityProperty': 'Demand',
      'powerCategory': 'demander',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Demand': 'number',
        'selling_price': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Demand': 'KW',
        'selling_price': '$/KW'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Demand (KW)': 5000,
        'Purchase Price ($/KW)': 0.1
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Demand': 'Demand',
        'Purchase Price': 'selling_price'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Demand': scheduleDataForHydrogenDemand('5000'),
        'selling_price': scheduleDataForHydrogenDemand('0.1')
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Demand': 5000,
      'selling_price': 0.1,
      'comments': []
    },
    'selected': true,
    'dragging': false,
    'positionAbsolute': {
      'x': 627.062836580373,
      'y': 244.80372474745263
    },
    'hidden': false
  },
  'ElectricityLB': {
    'width': 64,
    'height': 64,
    'id': 'Electricity_LB_2698',
    'name': 'Electricity LB',
    'type': 'dsiderNode',
    'position': {
      'x': 308.22851998283454,
      'y': 244.1489290508046
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'ElectricityLB',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Electricity+LB.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Electricity+LB.svg',
      'componentDisplayName': 'Electricity LB',
      'no_of_inputs': 5,
      'no_of_outputs': 3,
      'sensitivityFormCategory': 'ElectricityLB Capacity',
      'capacityProperty': null,
      'powerCategory': '',
      'input_node_arr': [
        1,
        2,
        3,
        4,
        5
      ],
      'output_node_arr': [
        1,
        2,
        3
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text'
      },
      'units': {
        'location': '',
        'abatement_location': ''
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74'
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74'
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 308.22851998283454,
      'y': 244.1489290508046
    },
    'hidden': false
  },
  'LB': {
    'width': 64,
    'height': 64,
    'id': 'LB_1176',
    'name': 'LB',
    'type': 'dsiderNode',
    'position': {
      'x': 656.4984886177892,
      'y': 263.14226490858005
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogenLB',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Hydrogen+LB.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Hydrogen+LB.svg',
      'componentDisplayName': 'LB',
      'no_of_inputs': 3,
      'no_of_outputs': 3,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': null,
      'input_node_arr': [
        1,
        2,
        3
      ],
      'output_node_arr': [
        1,
        2,
        3
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Residual_Hydrogen': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Residual_Hydrogen': '%'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Residual Hydrogen (%)': 0
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Residual Hydrogen': 'Residual_Hydrogen'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'Residual_Hydrogen': 0
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'Residual_Hydrogen': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 656.4984886177892,
      'y': 263.14226490858005
    },
    'hidden': false
  },
  'electrolyzer': {
    'width': 64,
    'height': 64,
    'id': 'Electrolyzer_4512',
    'name': 'Electrolyzer',
    'type': 'dsiderNode',
    'position': {
      'x': 476.912384417069,
      'y': 252.4000015258789
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'electrolyzer',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Electrolyzer.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Electrolyzer.svg',
      'componentDisplayName': 'Electrolyzer',
      'no_of_inputs': 2,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'capacity',
      'powerCategory': 'reactor',
      'input_node_arr': [
        1,
        2
      ],
      'output_node_arr': [
        1,
        2
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capex': 'number',
        'efficiency_rate': 'number',
        'conversion_factor': 'number',
        'capacity': 'number',
        'MaxElDecline': 'number',
        'MaxElRamp': 'number',
        'minimum_required_power': 'number',
        'opex': 'number',
        'period_cost': 'number',
        'yearly_degradation_rate': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capex': '$/KW',
        'efficiency_rate': '%',
        'conversion_factor': 'Kg/KW',
        'capacity': 'KW',
        'MaxElDecline': 'KW/H',
        'MaxElRamp': 'KW/H',
        'minimum_required_power': '%',
        'opex': '$/KW',
        'period_cost': '$/year/KW',
        'yearly_degradation_rate': '%'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 890,
        'Conversion Efficiency (%)': 60,
        'Conversion Factor (Kg/KW)': 0.20,
        'Initial Capacity (KW)': 100000,
        'Max Ramp Down % of Capacity (KW/H)': 150,
        'Max Ramp Up % of Capacity (KW/H)': 150,
        'Min Required Load (%)': 10,
        'Operating Cost ($/KW)': 0,
        'Period Cost ($/year/KW)': 44.5,
        'Yearly Degrade Factor (%)': 1
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capital Cost': 'capex',
        'Conversion Efficiency': 'efficiency_rate',
        'Conversion Factor': 'conversion_factor',
        'Initial Capacity': 'capacity',
        'Max Ramp Down % of Capacity': 'MaxElDecline',
        'Max Ramp Up % of Capacity': 'MaxElRamp',
        'Min Required Load': 'minimum_required_power',
        'Operating Cost': 'opex',
        'Period Cost': 'period_cost',
        'Yearly Degrade Factor': 'yearly_degradation_rate'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'capex': defaultCapexScheduleData('890'),
        'efficiency_rate': 60,
        'conversion_factor': 0.20,
        'capacity': 100000,
        'MaxElDecline': 150,
        'MaxElRamp': 150,
        'minimum_required_power': 10,
        'opex': scheduleDataForHydrogenDemand('0'),
        'period_cost': defaultPeriodCostScheduleData('44.5'),
        'yearly_degradation_rate': 1
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'capex': 890,
      'efficiency_rate': 60,
      'conversion_factor': 0.20,
      'capacity': 100000,
      'MaxElDecline': 150,
      'MaxElRamp': 150,
      'minimum_required_power': 10,
      'opex': 0,
      'period_cost': 44.5,
      'yearly_degradation_rate': 1,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 476.912384417069,
      'y': 252.4000015258789
    },
    'hidden': false
  },
  'hydrogen_storage': {
    'width': 64,
    'height': 64,
    'id': 'Hydrogen_Storage_2459',
    'name': 'Hydrogen Storage',
    'type': 'dsiderNode',
    'position': {
      'x': 782.8951832278881,
      'y': 70.98912849102061
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogen_storage',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Green+H2+Storage.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Green+H2+Storage.svg',
      'componentDisplayName': 'Hydrogen Storage',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'capacity',
      'powerCategory': 'mixed',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capacity': 'number',
        'capex': 'number',
        'charge_cost': 'number',
        'discharge_cost': 'number',
        'initial_capacity': 'number',
        'initial_fill_proportion': 'number',
        'period_cost': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capacity': 'Kg',
        'capex': '$/Kg',
        'charge_cost': '$/kg',
        'discharge_cost': '$/kg',
        'initial_capacity': 'Kg',
        'initial_fill_proportion': '%',
        'period_cost': '$/year/kg',
        'transmission_loss_proportion': '%'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Capacity (Kg)': 10000,
        'Capital Cost ($/Kg)': 76,
        'Charge Cost ($/kg)': 0,
        'Discharge cost ($/kg)': 0,
        'Initial Capacity (Kg)': 500,
        'Initial Fill Percent (%)': 80,
        'Period Cost ($/year/kg)': 17,
        'Transmission Loss Percent (%)': 0
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capacity': 'capacity',
        'Capital Cost': 'capex',
        'Charge Cost': 'charge_cost',
        'Discharge cost': 'discharge_cost',
        'Initial Capacity': 'initial_capacity',
        'Initial Fill Percent': 'initial_fill_proportion',
        'Period Cost': 'period_cost',
        'Transmission Loss Percent': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'capacity': 10000,
        'capex': defaultCapexScheduleData('76'),
        'charge_cost': scheduleDataForHydrogenDemand('0'),
        'discharge_cost': scheduleDataForHydrogenDemand('0'),
        'initial_capacity': 500,
        'initial_fill_proportion': 80,
        'period_cost': defaultPeriodCostScheduleData('17'),
        'transmission_loss_proportion': 0
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'capacity': 10000,
      'capex': 76,
      'charge_cost': 0,
      'discharge_cost': 0,
      'initial_capacity': 500,
      'initial_fill_proportion': 80,
      'period_cost': 17,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 782.8951832278881,
      'y': 70.98912849102061
    },
    'hidden': false
  },
  'external_hydrogen': {
    'width': 64,
    'height': 64,
    'id': 'External_Hydrogen_Supplier_6102',
    'name': 'External Hydrogen Supplier',
    'type': 'dsiderNode',
    'position': {
      'x': -136.9555176221561,
      'y': 478.5921628472526
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'external_hydrogen',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/External+Gray+Supply.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/External+Gray+Supply.svg',
      'componentDisplayName': 'External Hydrogen Supplier',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capacity': 'number',
        'carbon_intensity': 'number',
        'price': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capacity': 'Kg',
        'carbon_intensity': 'Kg CO2e/Kg H2',
        'price': '$/kg',
        'transmission_loss_proportion': '%'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Capacity (Kg)': 5000,
        'Carbon Intensity (Kg CO2e/Kg H2)': 0.01,
        'Purchase Price ($/kg)': 5,
        'Transmission Loss (%)': 0
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capacity': 'capacity',
        'Carbon Intensity': 'carbon_intensity',
        'Purchase Price': 'price',
        'Transmission Loss': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'capacity': 5000,
        'carbon_intensity': 0.01,
        'price': scheduleDataForHydrogenDemand('5'),
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capacity': 5000,
      'carbon_intensity': 0.01,
      'price': 5,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': -136.9555176221561,
      'y': 478.5921628472526
    },
    'hidden': false
  },
  'HydrogenDemand': {
    'width': 64,
    'height': 64,
    'id': 'Hydrogen_Demand_2319',
    'name': 'Hydrogen Demand',
    'type': 'dsiderNode',
    'position': {
      'x': 918.9848861778912,
      'y': 262.76011581787395
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogen',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Mobility.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Mobility.svg',
      'componentDisplayName': 'Hydrogen Demand',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'hydrogen_schedule',
      'powerCategory': 'demander',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'hydrogen_schedule': 'text',
        'price': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'hydrogen_schedule': 'kg',
        'price': '$/kg'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'H2 Demand Schedule (kg)': 'Yearly',
        'H2 Price ($/kg)': 1
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'H2 Demand Schedule': 'hydrogen_schedule',
        'H2 Price': 'price'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'hydrogen_schedule': scheduleDataForHydrogenDemand('500'),
        'price': scheduleDataForHydrogenDemand('1')
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'hydrogen_schedule': 500,
      'price': 1,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 918.9848861778912,
      'y': 262.76011581787395
    },
    'hidden': false
  },
  'FuelCellStack': {
    'width': 64,
    'height': 64,
    'id': 'Fuel_Cell_Stack_5610',
    'name': 'Fuel Cell Stack',
    'type': 'dsiderNode',
    'position': {
      'x': 21.37781571117739,
      'y': 478.59216284725255
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'FuelCellStack',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Fuel+Cell.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Fuel+Cell.svg',
      'componentDisplayName': 'Fuel Cell Stack',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'Power_Capacity',
      'powerCategory': 'reactor',
      'input_node_arr': [1],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capex': 'text',
        'conversion_factor': 'number',
        'emission_carbon_intensity': 'number',
        'minimum_required_hydrogen': 'number',
        'Eff': 'number',
        'opex': 'text',
        'Power_Capacity': 'number',
        'period_cost': 'text',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_opex': 'number',
        'transmission_loss_proportion': 'number',
        'yearly_degradation_rate': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Eff': 'Percentage (%)',
        'Capital_Cost': '$/KW',
        'capex': '$/KW',
        'conversion_factor': 'Kg/KW',
        'emission_carbon_intensity': 'Kg CO2e/Kg H2',
        'minimum_required_hydrogen': 'Percentage (%)',
        'Power_Capacity': 'KW',
        'opex': '$/KW',
        'period_cost': '$/KW/year',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_opex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)',
        'yearly_degradation_rate': 'Percentage (%)'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Capacity (KW)': 5000,
        'Capital Cost ($/KW)': 1320,
        'Conversion Efficiency (Percentage (%))': 55,
        'Conversion Factor (Kg/KW)': 33.6,
        'Emission Carbon Intensity (Kg CO2e/Kg H2)': 0.04,
        'Min Required Load (Percentage (%))': 0.5,
        'Operating Cost ($/KW)': 0.5,
        'Period Cost ($/year/KW)': 5,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Transmission Loss Percent (Percentage (%))': 0,
        'Yearly Degrade Factor (Percentage (%))': 0.2
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capital Cost': 'capex',
        'Capacity': 'Power_Capacity',
        'Conversion Efficiency': 'Eff',
        'Conversion Factor': 'conversion_factor',
        'Emission Carbon Intensity': 'emission_carbon_intensity',
        'Min Required Load': 'minimum_required_hydrogen',
        'Period Cost': 'period_cost',
        'Operating Cost': 'opex',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission Loss Percent': 'transmission_loss_proportion',
        'Yearly Degrade Factor': 'yearly_degradation_rate'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'Eff': 55,
        'Power_Capacity': 5000,
        'capex': defaultCapexScheduleData('1320'),
        'conversion_factor': 33.6,
        'emission_carbon_intensity': 0.04,
        'minimum_required_hydrogen': 0.5,
        'opex': defaultScheduleData('0.5'),
        'period_cost': defaultPeriodCostScheduleData('150', '0'),
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0,
        'yearly_degradation_rate': 0.2
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capex': 1320,
      'Eff': 55,
      'conversion_factor': 33.6,
      'Power_Capacity': 5000,
      'emission_carbon_intensity': 0.04,
      'minimum_required_hydrogen': 0.5,
      'opex': 0.5,
      'period_cost': 5,
      'comments': [],
      'transmission_loss_proportion': 0,
      'yearly_degradation_rate': 0.2,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0
    },
    'selected': true,
    'dragging': false,
    'positionAbsolute': {
      'x': 21.37781571117739,
      'y':
      478.59216284725255
    },
    'hidden': false
  }
};

export const POWER_SOURCES_EDGES: Record<string, Edge[]> = {
  'turbine': [
    {
      'source': 'Turbines_4129',
      'sourceHandle': 'output-1-Turbines_4129',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-3-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'selected': false,
      'id': 'reactflow__edge-Turbines_4129output-1-Turbines_4129-Electricity_LB_2698input-3-Electricity_LB_2698'
    }
  ],
  'solar_panel': [
    {
      'source': 'Solar_Panel_7328',
      'sourceHandle': 'output-1-Solar_Panel_7328',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-2-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'selected': false,
      'id': 'reactflow__edge-Solar_Panel_7328output-1-Solar_Panel_7328-Electricity_LB_2698input-2-Electricity_LB_2698'
    }
  ],
  'natural_gas': [
    {
      'source': 'Natural_Gas_3631',
      'sourceHandle': 'output-1-Natural_Gas_3631',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-4-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'selected': false,
      'hidden': false,
      'id': 'reactflow__edge-Natural_Gas_3631output-1-Natural_Gas_3631-Electricity_LB_2698input-4-Electricity_LB_2698'
    }
  ],
  'diesel': [
    {
      'source': 'Diesel_3486',
      'sourceHandle': 'output-1-Diesel_3486',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-1-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Diesel_3486output-1-Diesel_3486-Electricity_LB_2698input-1-Electricity_LB_2698',
      'selected': false,
      'hidden': false
    }
  ],
  'Grid': [
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-3-Electricity_LB_2698',
      'target': 'Grid_8207',
      'targetHandle': 'input-1-Grid_8207',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-3-Electricity_LB_2698-Grid_8207input-1-Grid_8207',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Grid_8207',
      'sourceHandle': 'output-1-Grid_8207',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-5-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'id': 'reactflow__edge-Grid_8207output-1-Grid_8207-Electricity_LB_2698input-5-Electricity_LB_2698'
    }
  ],
  'battery': [
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-1-Electricity_LB_2698',
      'target': 'Battery_5278',
      'targetHandle': 'input-1-Battery_5278',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-1-Electricity_LB_2698-Battery_5278input-1-Battery_5278',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Battery_5278',
      'sourceHandle': 'output-1-Battery_5278',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-1-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Battery_5278output-1-Battery_5278-Electricity_LB_2698input-1-Electricity_LB_2698',
      'hidden': false
    }
  ],
  'ElectricPowerDemand': [
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-2-Electricity_LB_2698',
      'target': 'Electric_Power_Demand_6385',
      'targetHandle': 'input-1-Electric_Power_Demand_6385',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-2-Electricity_LB_2698-Electric_Power_Demand_6385input-1-Electric_Power_Demand_6385',
      'hidden': false
    }
  ],
  'electrolyzer': [
    {
      'source': 'Electrolyzer_4512',
      'sourceHandle': 'output-2-Electrolyzer_4512',
      'target': 'LB_1176',
      'targetHandle': 'input-2-LB_1176',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electrolyzer_4512output-2-Electrolyzer_4512-LB_1176input-2-LB_1176',
      'hidden': false
    },
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-2-Electricity_LB_2698',
      'target': 'Electrolyzer_4512',
      'targetHandle': 'input-2-Electrolyzer_4512',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-2-Electricity_LB_2698-Electrolyzer_4512input-2-Electrolyzer_4512',
      'hidden': false
    }
  ],
  'hydrogen_storage': [
    {
      'source': 'LB_1176',
      'sourceHandle': 'output-1-LB_1176',
      'target': 'Hydrogen_Storage_2459',
      'targetHandle': 'input-1-Hydrogen_Storage_2459',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-LB_1176output-1-LB_1176-Hydrogen_Storage_2459input-1-Hydrogen_Storage_2459',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Hydrogen_Storage_2459',
      'sourceHandle': 'output-1-Hydrogen_Storage_2459',
      'target': 'LB_1176',
      'targetHandle': 'input-1-LB_1176',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Hydrogen_Storage_2459output-1-Hydrogen_Storage_2459-LB_1176input-1-LB_1176',
      'selected': false,
      'hidden': false
    }
  ],
  'external_hydrogen': [
    {
      'source': 'External_Hydrogen_Supplier_6102',
      'sourceHandle': 'output-1-External_Hydrogen_Supplier_6102',
      'target': 'LB_1176',
      'targetHandle': 'input-3-LB_1176',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-External_Hydrogen_Supplier_6102output-1-External_Hydrogen_Supplier_6102-LB_1176input-3-LB_1176',
      'hidden': false,
      'selected': false
    }, {
      'source': 'External_Hydrogen_Supplier_6102',
      'sourceHandle': 'output-1-External_Hydrogen_Supplier_6102',
      'target': 'Fuel_Cell_Stack_5610',
      'targetHandle': 'input-1-Fuel_Cell_Stack_5610',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-External_Hydrogen_Supplier_6102output-1-External_Hydrogen_Supplier_6102-Fuel_Cell_Stack_5610input-1-Fuel_Cell_Stack_5610',
      'hidden': false,
      'selected': false
    }
  ],
  'HydrogenDemand': [
    {
      'source': 'LB_1176',
      'sourceHandle': 'output-2-LB_1176',
      'target': 'Hydrogen_Demand_2319',
      'targetHandle': 'input-1-Hydrogen_Demand_2319',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-LB_1176output-2-LB_1176-Hydrogen_Demand_2319input-1-Hydrogen_Demand_2319',
      'hidden': false
    }
  ],
  'FuelCellStack': [
    {
      'source': 'Fuel_Cell_Stack_5610',
      'sourceHandle': 'output-1-Fuel_Cell_Stack_5610',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-4-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Fuel_Cell_Stack_5610output-1-Fuel_Cell_Stack_5610-Electricity_LB_2698input-5-Electricity_LB_2698',
      'hidden': false,
      'selected': false
    }]
};

export const WIZARD_SCENARIO_VIEWPORT = {
  'x': 60.173310573293634,
  'y': 71.84470229164847,
  'zoom': 0.6
};

export const CCUS_WIZARD_SCENARIO_VIEWPORT = {
  'x': 170.17331057329358,
  'y': 142.84470229164847,
  'zoom': 0.5
};

export const CCUS_SOURCES: Record<string, IDsiderNode> = {
  'flue_gas_supplier': {
    'width': 64,
    'height': 64,
    'id': 'Flue_Gas_2235_4992_2513_1733_7957',
    'name': 'Flue Gas',
    'type': 'dsiderNode',
    'position': {
      'x': -216.51357512629215,
      'y': -115.05148168080098
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'flue_gas_supplier',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Flue-Gas-Supplier.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Flue-Gas-Supplier-Dark.svg',
      'componentDisplayName': 'Flue Gas',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Flue Gas',
      'capacityProperty': 'capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capacity': 'number',
        'co2_volumetric_concentration': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capacity': 'Nm^3/hr',
        'co2_volumetric_concentration': 'vol%'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'CO2 Volumetric Concentration (vol%)': 10,
        'Capacity (Nm^3/hr)': 600
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'CO2 Volumetric Concentration': 'co2_volumetric_concentration',
        'Capacity': 'capacity'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'capacity': 600,
        'co2_volumetric_concentration': 10
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capacity': 600,
      'co2_volumetric_concentration': 10,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': -216.51357512629215,
      'y': -115.05148168080098
    },
    'hidden': false
  },
  'mitico': {
    'width': 64,
    'height': 64,
    'id': 'Mitico_727_3600_6389_797_1018',
    'name': 'Mitico',
    'type': 'dsiderNode',
    'position': {
      'x': 238.6447000566318,
      'y': -173.5181574038303
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'mitico',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Mitico.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Mitico-Dark.svg',
      'componentDisplayName': 'Mitico',
      'no_of_inputs': 3,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Mitico',
      'capacityProperty': ['efficiency_proportion', 'sorption_capacity'],
      'powerCategory': 'mixed',
      'input_node_arr': [
        1, 2, 3
      ],
      'output_node_arr': [
        1, 2
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'cooldown_time': 'number',
        'cycles_until_replacement': 'number',
        'desorption_time': 'number',
        'efficiency_proportion': 'number',
        'natural_gas_demand_schedule': 'number',
        'num_cycles_until_rejuvenation': 'number',
        'opex': 'text',
        'period_cost': 'text',
        'power_demand_schedule': 'number',
        'rejuvenation_costs': 'number',
        'rejuvenation_downtime': 'number',
        'rejuvenation_emissions': 'number',
        'replacement_costs': 'number',
        'replacement_downtime': 'number',
        'replacement_emissions': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'sorption_capacity': 'number',
        'capex': 'text',
        'sorption_time': 'number',
        'warmup_time': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'cooldown_time': 'hrs',
        'cycles_until_replacement': 'cycles',
        'desorption_time': 'hrs',
        'efficiency_proportion': '%',
        'natural_gas_demand_schedule': 'm^3/mt',
        'num_cycles_until_rejuvenation': 'cycles',
        'opex': '$/mt',
        'period_cost': '$/year',
        'power_demand_schedule': 'kWh/mt',
        'rejuvenation_costs': '$',
        'rejuvenation_downtime': 'hrs',
        'rejuvenation_emissions': 'mt/cycle',
        'replacement_costs': '$/cycle',
        'replacement_downtime': 'hrs',
        'replacement_emissions': 'mt/cycle',
        'scope_1_opex': 'kg CO2e/m^3',
        'scope_2_opex': 'kg CO2e/m^3',
        'scope_3_capex': 'kg CO2e',
        'sorption_capacity': 'mt/hour',
        'capex': '$',
        'sorption_time': 'hrs',
        'warmup_time': 'hrs'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Cooldown Time (hrs)': 0.5,
        'Cycles until Rejuvenation (cycles)': 540,
        'Cycles until Replacement (cycles)': 4,
        'Desorption Time (hrs)': 0.75,
        'Efficiency Proportion (%)': 100,
        'Natural Gas Demand (m^3/mt)': 5,
        'Non-energy Operating Cost ($/mt)': 21.08,
        'Period Cost ($/year)': 0,
        'Power Demand (kWh/mt)': 32,
        'Rejuvenation Costs ($)': 533.56,
        'Rejuvenation Downtime (hrs)': 0.1,
        'Rejuvenation Emissions (mt/cycle)': 1,
        'Replacement Costs ($/cycle)': 1996.72,
        'Replacement Downtime (hrs)': 8,
        'Replacement Emissions (mt/cycle)': 10,
        'Scope 1 Intensity (kg CO2e/m^3)': 0,
        'Scope 2 Intensity (kg CO2e/m^3)': 0,
        'Scope 3 Intensity (kg CO2)': 0,
        'Sorption Capacity (mt/hour)': 0.108,
        'Capital Cost ($)': 80,
        'Sorption Time (hrs)': 2,
        'Warm Up Time (hrs)': 0.75
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Cooldown Time': 'cooldown_time',
        'Cycles until Rejuvenation': 'num_cycles_until_rejuvenation',
        'Cycles until Replacement': 'cycles_until_replacement',
        'Desorption Time': 'desorption_time',
        'Efficiency Proportion': 'efficiency_proportion',
        'Natural Gas Demand': 'natural_gas_demand_schedule',
        'Non-energy Operating Cost': 'opex',
        'Period Cost': 'period_cost',
        'Power Demand': 'power_demand_schedule',
        'Rejuvenation Costs': 'rejuvenation_costs',
        'Rejuvenation Downtime': 'rejuvenation_downtime',
        'Rejuvenation Emissions': 'rejuvenation_emissions',
        'Replacement Costs': 'replacement_costs',
        'Replacement Downtime': 'replacement_downtime',
        'Replacement Emissions': 'replacement_emissions',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Sorption Capacity': 'sorption_capacity',
        'Capital Cost': 'capex',
        'Sorption Time': 'sorption_time',
        'Warm Up Time': 'warmup_time'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'cooldown_time': 0.5,
        'cycles_until_replacement': 4,
        'desorption_time': 0.75,
        'efficiency_proportion': 100,
        'natural_gas_demand_schedule': 5,
        'num_cycles_until_rejuvenation': 540,
        'opex': defaultOpexData('21.08', '21.08'),
        'period_cost': defaultPeriodCostScheduleData('0'),
        'power_demand_schedule': 32,
        'rejuvenation_costs': 533.56,
        'rejuvenation_downtime': 0.1,
        'rejuvenation_emissions': 1,
        'replacement_costs': 1996.72,
        'replacement_downtime': 8,
        'replacement_emissions': 10,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'sorption_capacity': 0.108,
        'capex': defaultCapexScheduleData('80'),
        'sorption_time': 2,
        'warmup_time': 0.75
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capex': 80,
      'cooldown_time': 0.5,
      'cycles_until_replacement': 4,
      'desorption_time': 0.75,
      'efficiency_proportion': 100,
      'natural_gas_demand_schedule': 5,
      'num_cycles_until_rejuvenation': 540,
      'opex': 21.08,
      'period_cost': 0,
      'power_demand_schedule': 32,
      'rejuvenation_costs': 533.56,
      'rejuvenation_downtime': 0.1,
      'rejuvenation_emissions': 1,
      'replacement_costs': 1996.72,
      'replacement_downtime': 8,
      'replacement_emissions': 10,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'sorption_capacity': 0.108,
      'sorption_time': 2,
      'warmup_time': 0.75,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 238.6447000566318,
      'y': -173.5181574038303
    },
    'hidden': false
  },
  'mitico1': {
    'width': 64,
    'height': 64,
    'id': 'Mitico_7271808-copy_2960_1637_8217_7419',
    'name': 'Mitico',
    'type': 'dsiderNode',
    'position': {
      'x': 243.89051127895414,
      'y': 86.74566127462799
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'mitico',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Mitico.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Mitico-Dark.svg',
      'componentDisplayName': 'Mitico-1',
      'no_of_inputs': 3,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Mitico',
      'capacityProperty': ['efficiency_proportion', 'sorption_capacity'],
      'powerCategory': 'mixed',
      'input_node_arr': [
        1, 2, 3
      ],
      'output_node_arr': [
        1, 2
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'cooldown_time': 'number',
        'cycles_until_replacement': 'number',
        'desorption_time': 'number',
        'efficiency_proportion': 'number',
        'natural_gas_demand_schedule': 'number',
        'num_cycles_until_rejuvenation': 'number',
        'opex': 'text',
        'period_cost': 'text',
        'power_demand_schedule': 'number',
        'rejuvenation_costs': 'number',
        'rejuvenation_downtime': 'number',
        'rejuvenation_emissions': 'number',
        'replacement_costs': 'number',
        'replacement_downtime': 'number',
        'replacement_emissions': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'sorption_capacity': 'number',
        'capex': 'text',
        'sorption_time': 'number',
        'warmup_time': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'cooldown_time': 'hrs',
        'cycles_until_replacement': 'cycles',
        'desorption_time': 'hrs',
        'efficiency_proportion': '%',
        'natural_gas_demand_schedule': 'm^3/mt',
        'num_cycles_until_rejuvenation': 'cycles',
        'opex': '$/mt',
        'period_cost': '$/year',
        'power_demand_schedule': 'kWh/mt',
        'rejuvenation_costs': '$',
        'rejuvenation_downtime': 'hrs',
        'rejuvenation_emissions': 'mt/cycle',
        'replacement_costs': '$/cycle',
        'replacement_downtime': 'hrs',
        'replacement_emissions': 'mt/cycle',
        'scope_1_opex': 'kg CO2e/m^3',
        'scope_2_opex': 'kg CO2e/m^3',
        'scope_3_capex': 'kg CO2e',
        'sorption_capacity': 'mt/hour',
        'capex': '$',
        'sorption_time': 'hrs',
        'warmup_time': 'hrs'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Cooldown Time (hrs)': 0.5,
        'Cycles until Rejuvenation (cycles)': 540,
        'Cycles until Replacement (cycles)': 4,
        'Desorption Time (hrs)': 0.75,
        'Efficiency Proportion (%)': 100,
        'Natural Gas Demand (m^3/mt)': 5,
        'Non-energy Operating Cost ($/mt)': 21.08,
        'Period Cost ($/year)': 0,
        'Power Demand (kWh/mt)': 32,
        'Rejuvenation Costs ($)': 533.56,
        'Rejuvenation Downtime (hrs)': 0.1,
        'Rejuvenation Emissions (mt/cycle)': 1,
        'Replacement Costs ($/cycle)': 1996.72,
        'Replacement Downtime (hrs)': 8,
        'Replacement Emissions (mt/cycle)': 10,
        'Scope 1 Intensity (kg CO2e/m^3)': 0,
        'Scope 2 Intensity (kg CO2e/m^3)': 0,
        'Scope 3 Intensity (kg CO2)': 0,
        'Sorption Capacity (mt/hour)': 0.108,
        'Capital Cost ($)': 150000,
        'Sorption Time (hrs)': 2,
        'Warm Up Time (hrs)': 0.75
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Cooldown Time': 'cooldown_time',
        'Cycles until Rejuvenation': 'num_cycles_until_rejuvenation',
        'Cycles until Replacement': 'cycles_until_replacement',
        'Desorption Time': 'desorption_time',
        'Efficiency Proportion': 'efficiency_proportion',
        'Natural Gas Demand': 'natural_gas_demand_schedule',
        'Non-energy Operating Cost': 'opex',
        'Period Cost': 'period_cost',
        'Power Demand': 'power_demand_schedule',
        'Rejuvenation Costs': 'rejuvenation_costs',
        'Rejuvenation Downtime': 'rejuvenation_downtime',
        'Rejuvenation Emissions': 'rejuvenation_emissions',
        'Replacement Costs': 'replacement_costs',
        'Replacement Downtime': 'replacement_downtime',
        'Replacement Emissions': 'replacement_emissions',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Sorption Capacity': 'sorption_capacity',
        'Capital Cost': 'capex',
        'Sorption Time': 'sorption_time',
        'Warm Up Time': 'warmup_time'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'cooldown_time': 0.5,
        'cycles_until_replacement': 4,
        'desorption_time': 0.75,
        'efficiency_proportion': 100,
        'natural_gas_demand_schedule': 5,
        'num_cycles_until_rejuvenation': 540,
        'opex': defaultOpexData('21.08', '21.08'),
        'period_cost': defaultPeriodCostScheduleData('0'),
        'power_demand_schedule': 32,
        'rejuvenation_costs': 533.56,
        'rejuvenation_downtime': 0.1,
        'rejuvenation_emissions': 1,
        'replacement_costs': 1996.72,
        'replacement_downtime': 8,
        'replacement_emissions': 10,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'sorption_capacity': 0.108,
        'capex': defaultCapexScheduleData('150000'),
        'sorption_time': 2,
        'warmup_time': 0.75
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capex': 150000,
      'cooldown_time': 0.5,
      'cycles_until_replacement': 4,
      'desorption_time': 0.75,
      'efficiency_proportion': 100,
      'natural_gas_demand_schedule': 5,
      'num_cycles_until_rejuvenation': 540,
      'opex': 21.08,
      'period_cost': 0,
      'power_demand_schedule': 32,
      'rejuvenation_costs': 533.56,
      'rejuvenation_downtime': 0.1,
      'rejuvenation_emissions': 1,
      'replacement_costs': 1996.72,
      'replacement_downtime': 8,
      'replacement_emissions': 10,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'sorption_capacity': 0.108,
      'sorption_time': 2,
      'warmup_time': 0.75,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 243.89051127895414,
      'y': 86.74566127462799
    },
    'hidden': false
  },
  'natural_gas_supplier': {
    'width': 64,
    'height': 64,
    'id': 'Natural_Gas_8486_8416_2951_9910_9423',
    'name': 'Natural Gas',
    'type': 'dsiderNode',
    'position': {
      'x': 241.8475544565215,
      'y': -49.47272585534728
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'natural_gas_supplier',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/gas.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/gas.svg',
      'componentDisplayName': 'Natural Gas',
      'no_of_inputs': 2,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Natural Gas Supplier',
      'capacityProperty': ['capacity'],
      'powerCategory': 'supplier',
      'input_node_arr': [
        1, 2
      ],
      'output_node_arr': [
        1, 2
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'capacity': 'number',
        'fuel_price_schedule': 'text',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'capacity': 'm^3',
        'fuel_price_schedule': '$/m^3',
        'scope_1_opex': 'CO2e/m^3',
        'scope_2_opex': 'CO2e/m^3',
        'scope_3_capex': 'CO2e/m^3',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Capacity (m^3)': 10000,
        'Scope 1 Intensity (CO2e/m^3)': 0.0001,
        'Scope 2 Intensity (CO2e/m^3)': 0,
        'Scope 3 Intensity (CO2e/m^3)': 0,
        'Sorption Capacity (mt/hour)': 0.108,
        'Transmission loss proportion (Percentage (%))': 0,
        'Fuel Price ($/m^3)': 2.83
      },
      'formulaTitle': {
        'Location': 'location',
        'Capacity': 'capacity',
        'Fuel Price': 'fuel_price_schedule',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'capacity': 10000,
        'fuel_price_schedule': scheduleDataForHydrogenDemand('2.83'),
        'scope_1_opex': 0.0001,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capacity': 10000,
      'scope_1_opex': 0.0001,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 0,
      'fuel_price_schedule': 2.83,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 241.847554456521,
      'y': -49.47272585534728
    },
    'hidden': false
  },
  'fluegasLB': {
    'width': 64,
    'height': 64,
    'id': 'Flue_Gas_Load_Balancer_204_8715_1737_7486_266',
    'name': 'Flue Gas Load Balancer',
    'type': 'dsiderNode',
    'position': {
      'x': 29.716770785971562,
      'y': -114.06530710336136
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'fluegasLB',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Hydrogen+LB.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Hydrogen+LB.svg',
      'componentDisplayName': 'Flue Gas Load Balancer',
      'no_of_inputs': 3,
      'no_of_outputs': 2,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': null,
      'input_node_arr': [
        1, 2, 3
      ],
      'output_node_arr': [
        1, 3
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text'
      },
      'units': {
        'location': '',
        'abatement_location': ''
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421'
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421'
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 29.716770785971562,
      'y': -114.06530710336136
    },
    'hidden': false
  },
  'co2LB': {
    'width': 64,
    'height': 64,
    'id': 'CO2_Load_Balancer_3758_4605_6120_9407_1338',
    'name': 'CO2 Load Balancer',
    'type': 'dsiderNode',
    'position': {
      'x': 572.9178259656164,
      'y': -57.80110088283021
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'co2LB',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Hydrogen+LB.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Hydrogen+LB.svg',
      'componentDisplayName': 'CO2 Load Balancer',
      'no_of_inputs': 3,
      'no_of_outputs': 1,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': null,
      'input_node_arr': [1, 2, 3],
      'output_node_arr': [
        3
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text'
      },
      'units': {
        'location': '',
        'abatement_location': ''
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421'
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421'
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 572.9178259656164,
      'y': -57.80110088283021
    },
    'hidden': false
  },
  'liquefaction': {
    'width': 64,
    'height': 64,
    'id': 'Liquefaction_4403_7326_7166_4073_4890',
    'name': 'Liquefaction',
    'type': 'dsiderNode',
    'position': {
      'x': 585.1446004743098,
      'y': 336.26682058777055
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'liquefaction',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/gas.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/gas.svg',
      'componentDisplayName': 'Liquefaction',
      'no_of_inputs': 3,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Liquefaction',
      'capacityProperty': 'gas_capacity',
      'powerCategory': 'reactor',
      'input_node_arr': [1, 2, 3],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capex_schedule': 'text',
        'conversion_factor': 'number',
        'gas_capacity': 'number',
        'minimum_required_gas': 'number',
        'op_costs_schedule': 'text',
        'period_costs_schedule': 'text',
        'power_capacity': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capex_schedule': '$',
        'conversion_factor': 'kg/m^3',
        'gas_capacity': 'mt/hr',
        'minimum_required_gas': 'mt/hr',
        'op_costs_schedule': '$/mt',
        'period_costs_schedule': '$/mt/year',
        'power_capacity': 'kWh/mt',
        'scope_1_opex': 'kg CO2e/kg CO2',
        'scope_2_opex': 'kg CO2e/kg CO2',
        'scope_3_capex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Capital Cost ($)': 34200,
        'Gas Capacity (mt/hr)': 1,
        'Minimum required gas (mt/hr)': 0.0108,
        'Operation Cost ($/mt)': 0,
        'Period Cost ($/mt/year)': 0,
        'Power Capacity (kWh/mt)': 5,
        'Scope 1 Intensity (kg CO2e/kg CO2)': 0,
        'Scope 2 Intensity (kg CO2e/kg CO2)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Transmission loss proportion (Percentage (%))': 0
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'CO2 Volumetric Concentration': 'co2_volumetric_concentration',
        'Capacity': 'capacity',
        'Capital Cost': 'capex_schedule',
        'Conversion factor': 'conversion_factor',
        'Gas Capacity': 'gas_capacity',
        'Minimum required gas': 'minimum_required_gas',
        'Operation Cost': 'op_costs_schedule',
        'Period Cost': 'period_costs_schedule',
        'Power Capacity': 'power_capacity',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'capex_schedule': defaultCapexScheduleData('34200'),
        'gas_capacity': 1,
        'minimum_required_gas': 0.0108,
        'op_costs_schedule': scheduleDataForHydrogenDemand('0'),
        'period_costs_schedule': defaultPeriodCostScheduleData('0'),
        'power_capacity': 5,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capex_schedule': 34200,
      'gas_capacity': 1,
      'minimum_required_gas': 0.0108,
      'op_costs_schedule': 0,
      'period_costs_schedule': 0,
      'power_capacity': 5,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 585.1446004743098,
      'y': 336.26682058777055
    },
    'hidden': false
  },
  'sequestration': {
    'width': 64,
    'height': 64,
    'id': 'Sequestration_6954_3582',
    'name': 'Sequestration',
    'type': 'dsiderNode',
    'position': {
      'x': 870.3606397301978,
      'y': 335.9140381110218
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'sequestration',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/genericwhite.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/genericdark.svg',
      'componentDisplayName': 'Sequestration',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'Sequestration',
      'capacityProperty': ['price_schedule', 'capacity'],
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'price_schedule': 'text',
        'capacity': 'number'
      },
      'units': {
        'location': '',
        'price_schedule': '$/kg',
        'capacity': 'kg/hr'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Capacity (kg/hr)': 100000,
        'Sell Price ($/kg)': 0.085
      },
      'formulaTitle': {
        'Location': 'location',
        'Capacity': 'capacity',
        'Sell Price': 'price_schedule'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'capacity': 100000,
        'price_schedule': scheduleDataForHydrogenDemand('0.085')
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capacity': 100000,
      'gas_capacity': 1,
      'price_schedule': 0.085,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 870.3606397301978,
      'y': 335.9140381110218
    },
    'hidden': false
  },
  'gaseous_co2_demander': {
    'width': 64,
    'height': 64,
    'id': 'CO2_Demand_293_8695_5094_212',
    'name': 'CO2 Demand',
    'type': 'dsiderNode',
    'position': {
      'x': 808.3113909579172,
      'y': -58.340076834704064
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'gaseous_co2_demander',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/co2white.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/co2dark.svg',
      'componentDisplayName': 'CO2 Demand',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'CO2 Demand',
      'capacityProperty': ['price', 'co2_demand_schedule'],
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'price': 'text',
        'co2_demand_schedule': 'text'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'price': '$/kg',
        'co2_demand_schedule': 'kg/hr'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Demand Schedule (kg/hr)': 200,
        'Purchase Price ($/kg)': 0.065
      },
      'formulaTitle': {
        'Location': 'location',
        'Demand Schedule': 'co2_demand_schedule',
        'Purchase Price': 'price',
        'Abatement Location': 'abatement_location'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'co2_demand_schedule': scheduleDataForHydrogenDemand('200'),
        'price': scheduleDataForHydrogenDemand('0.065')
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'co2_demand_schedule': 200,
      'price': 0.065,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 808.3113909579172,
      'y': -58.340076834704064
    },
    'hidden': false
  },
  'tower': {
    'width': 64,
    'height': 64,
    'id': 'Tower_9087_1426_5925_8447_8359',
    'name': 'Tower',
    'type': 'dsiderNode',
    'position': {
      'x': 342.4090098016926,
      'y': -205.40345561388523
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'tower',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/MeroxTreater.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/MeroxTreater.svg',
      'componentDisplayName': 'Tower',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Tower',
      'capacityProperty': ['flow_area', 'tower_capex_schedule'],
      'powerCategory': 'mixed',
      'input_node_arr': [1],
      'output_node_arr': [1],
      'defaultType': 'default',
      'propertiesType': {
        'capture_factor': 'number',
        'co2_volumetric_concentration': 'number',
        'flow_area': 'number',
        'tower_capex_schedule': 'text',
        'tower_demand_schedule': 'text',
        'tower_op_costs_schedule': 'text',
        'tower_period_costs_schedule': 'text'
      },
      'units': {
        'capture_factor': '%',
        'co2_volumetric_concentration': '% vol',
        'flow_area': 'm^2',
        'tower_capex_schedule': '$/ton',
        'tower_demand_schedule': 'kWh/ton',
        'tower_op_costs_schedule': '$/ton',
        'tower_period_costs_schedule': '$/ton/year'
      },
      'properties': {
        'CO2 Volumetric Concentration (% vol)': '0.04',
        'Capital Cost ($/mt)': '120',
        'Capture Factor (%)': '75',
        'Demand Schedule (kWh/mt)': '120',
        'Flow Area (m^2)': '750000',
        'Operation Cost ($/mt)': '0.2',
        'Period Cost ($/mt/year)': '45'
      },
      'formulaTitle': {
        'CO2 Volumetric Concentration': 'co2_volumetric_concentration',
        'Capital Cost': 'tower_capex_schedule',
        'Capture Factor': 'capture_factor',
        'Demand Schedule': 'tower_demand_schedule',
        'Flow Area': 'flow_area',
        'Operation Cost': 'tower_op_costs_schedule',
        'Period Cost': 'tower_period_costs_schedule'
      },
      'propertiesValues': {
        'capture_factor': '75',
        'co2_volumetric_concentration': '0.04',
        'flow_area': '750000',
        'tower_capex_schedule': defaultCapexScheduleData('120'),
        'tower_demand_schedule': scheduleDataForHydrogenDemand('120'),
        'tower_op_costs_schedule': scheduleDataForHydrogenDemand('0.2'),
        'tower_period_costs_schedule': defaultPeriodCostScheduleData('45')
      },
      'capture_factor': '75',
      'co2_volumetric_concentration': '0.04',
      'flow_area': '750000',
      'tower_capex_schedule': '120',
      'tower_demand_schedule': '120',
      'tower_op_costs_schedule': '0.2',
      'tower_period_costs_schedule': '45',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 342.4090098016926,
      'y': -205.40345561388523
    },
    'hidden': false
  },
  'dac_fan': {
    'width': 64,
    'height': 64,
    'id': 'DAC_Fan_3682_1735_5474_5422_9689',
    'name': 'DAC Fan',
    'type': 'dsiderNode',
    'position': {
      'x': 160.4439573433217,
      'y': -213.85617518081568
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'dac_fan',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/genericwhite.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/genericdark.svg',
      'componentDisplayName': 'DAC Fan',
      'no_of_inputs': 1,
      'no_of_outputs': 2,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': 'mixed',
      'input_node_arr': [1],
      'output_node_arr': [1, 2],
      'defaultType': 'default',
      'propertiesType': {
        'air_speed_schedule': 'text',
        'fan_capex_schedule': 'text',
        'fan_demand_schedule': 'text',
        'fan_op_costs_schedule': 'text',
        'fan_period_costs_schedule': 'text'
      },
      'units': {
        'air_speed_schedule': 'm/s',
        'fan_capex_schedule': '$/ton',
        'fan_demand_schedule': 'Kwh/ton',
        'fan_op_costs_schedule': '$/ton',
        'fan_period_costs_schedule': '$/ton/year'
      },
      'properties': {
        'Air Speed Schedule (m/s)': '77',
        'Capital Cost ($/mt)': '80',
        'Demand Schedule (kWh/mt)': '260',
        'Operation Cost ($/mt)': '0.2',
        'Period Cost ($/mt/year)': '23'
      },
      'formulaTitle': {
        'Air Speed Schedule': 'air_speed_schedule',
        'Capital Cost': 'fan_capex_schedule',
        'Demand Schedule': 'fan_demand_schedule',
        'Operation Cost': 'fan_op_costs_schedule',
        'Period Cost': 'fan_period_costs_schedule'
      },
      'propertiesValues': {
        'air_speed_schedule': defaultCapexScheduleData('77'),
        'fan_capex_schedule': defaultCapexScheduleData('80'),
        'fan_demand_schedule': scheduleDataForHydrogenDemand('260'),
        'fan_op_costs_schedule': scheduleDataForHydrogenDemand('0.2'),
        'fan_period_costs_schedule': defaultPeriodCostScheduleData('23')
      },
      'air_speed_schedule': '77',
      'fan_capex_schedule': '80',
      'fan_demand_schedule': '260',
      'fan_op_costs_schedule': '0.2',
      'fan_period_costs_schedule': '23',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 160.4439573433217,
      'y': -213.85617518081568
    },
    'hidden': false
  },
  'process_pump': {
    'width': 64,
    'height': 64,
    'id': 'Process_Pump_2828_6109_4668_4711_3777',
    'name': 'Process Pump',
    'type': 'dsiderNode',
    'position': {
      'x': 183.01821629474716,
      'y': -28.838567543366793
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'process_pump',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/pump.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/pump.svg',
      'componentDisplayName': 'Process Pump',
      'no_of_inputs': 1,
      'no_of_outputs': 2,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [1, 2],
      'defaultType': 'default',
      'propertiesType': {
        'process_pump_capex_schedule': 'text',
        'process_pump_demand_schedule': 'text',
        'process_pump_op_costs_schedule': 'text',
        'process_pump_period_costs_schedule': 'text'
      },
      'units': {
        'process_pump_capex_schedule': '$/ton',
        'process_pump_demand_schedule': 'kWh/ton',
        'process_pump_op_costs_schedule': '$/ton',
        'process_pump_period_costs_schedule': '$/ton/year'
      },
      'properties': {
        'Capital Expense ($/mt)': '40',
        'Demand Schedule (kWh/mt)': '10',
        'Operation Cost ($/mt)': '0.2',
        'Period Cost ($/mt/year)': '6'
      },
      'formulaTitle': {
        'Capex': 'process_pump_capex_schedule',
        'Demand Schedule': 'process_pump_demand_schedule',
        'Operational Costs': 'process_pump_op_costs_schedule',
        'Period Cost': 'process_pump_period_costs_schedule'
      },
      'propertiesValues': {
        'process_pump_capex_schedule': defaultCapexScheduleData('40'),
        'process_pump_demand_schedule': scheduleDataForHydrogenDemand('10'),
        'process_pump_op_costs_schedule': scheduleDataForHydrogenDemand('0.2'),
        'process_pump_period_costs_schedule': defaultPeriodCostScheduleData('6')
      },
      'process_pump_capex_schedule': '40',
      'process_pump_demand_schedule': '10',
      'process_pump_op_costs_schedule': '0.2',
      'process_pump_period_costs_schedule': '6',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 183.01821629474716,
      'y': -28.838567543366793
    },
    'hidden': false
  },
  'transfer_pump': {
    'width': 64,
    'height': 64,
    'id': 'Transfer_Pump_1654_6463_8678_6551_6956',
    'name': 'Transfer Pump',
    'type': 'dsiderNode',
    'position': {
      'x': 336.8031516532824,
      'y': -18.772663126588867
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'transfer_pump',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/pump.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/pump.svg',
      'componentDisplayName': 'Transfer Pump',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [1],
      'defaultType': 'default',
      'propertiesType': {
        'transfer_pump_capex_schedule': 'text',
        'transfer_pump_demand_schedule': 'text',
        'transfer_pump_op_costs_schedule': 'text',
        'transfer_pump_period_costs_schedule': 'text'
      },
      'units': {
        'transfer_pump_capex_schedule': '$/ton',
        'transfer_pump_demand_schedule': 'kWh/ton',
        'transfer_pump_op_costs_schedule': '$/ton',
        'transfer_pump_period_costs_schedule': '$/ton/year'
      },
      'properties': {
        'Capital Expense ($/mt)': '40',
        'Demand Schedule (kWh/mt)': '50',
        'Operation Cost ($/mt)': '0.2',
        'Period Cost ($/mt/year)': '6'
      },
      'formulaTitle': {
        'Capex': 'transfer_pump_capex_schedule',
        'Demand Schedule': 'transfer_pump_demand_schedule',
        'Operational Costs': 'transfer_pump_op_costs_schedule',
        'Period Cost': 'transfer_pump_period_costs_schedule'
      },
      'propertiesValues': {
        'transfer_pump_capex_schedule': defaultCapexScheduleData('40'),
        'transfer_pump_demand_schedule': scheduleDataForHydrogenDemand('50'),
        'transfer_pump_op_costs_schedule': scheduleDataForHydrogenDemand('0.2'),
        'transfer_pump_period_costs_schedule': defaultPeriodCostScheduleData('6')
      },
      'transfer_pump_capex_schedule': '40',
      'transfer_pump_demand_schedule': '50',
      'transfer_pump_op_costs_schedule': '0.2',
      'transfer_pump_period_costs_schedule': '6',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 336.8031516532824,
      'y': -18.772663126588867
    },
    'hidden': false
  },
  'balance_of_plant': {
    'width': 64,
    'height': 64,
    'id': 'Balance_of_Plant_9491_546_4728_8568_4449',
    'name': 'Balance of Plant',
    'type': 'dsiderNode',
    'position': {
      'x': 267.82232288394925,
      'y': -119.56749112999745
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'balance_of_plant',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/ORC+Powerplant.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/ORC+Powerplant.svg',
      'componentDisplayName': 'Balance of Plant',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [1],
      'defaultType': 'default',
      'propertiesType': {
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'bop_capex_schedule': 'text',
        'bop_period_costs_schedule': 'text'
      },
      'units': {
        'scope_1_opex': 'kg CO2e/ton',
        'scope_2_opex': 'kg CO2e/ton',
        'scope_3_capex': 'kg CO2e/ton',
        'bop_capex_schedule': '$',
        'bop_period_costs_schedule': '$/ton/year'
      },
      'properties': {
        'Scope 1 Intensity (kg CO2e/mt)': '0',
        'Capital Cost ($)': '71',
        'Scope 2 Intensity (kg CO2e/mt)': '0',
        'Scope 3 Intensity (kg CO2e/mt)': '0',
        'Period Cost ($/mt/year)': '0'
      },
      'formulaTitle': {
        'Scope 1 Intensity': 'scope_1_opex',
        'Capital Cost': 'bop_capex_schedule',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Intensity': 'scope_3_opex',
        'Period Cost': 'bop_period_costs_schedule'
      },
      'propertiesValues': {
        'scope_1_opex': '0',
        'scope_2_opex': '0',
        'scope_3_opex': '0',
        'bop_capex_schedule': defaultCapexScheduleData('71'),
        'bop_period_costs_schedule': defaultPeriodCostScheduleData('0')
      },
      'scope_1_opex': '0',
      'scope_2_opex': '0',
      'scope_3_opex': '0',
      'bop_capex_schedule': '71',
      'bop_period_costs_schedule': '0',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 267.82232288394925,
      'y': -119.56749112999745
    },
    'hidden': false
  },
  'regeneration': {
    'width': 64,
    'height': 64,
    'id': 'Regeneration_4828_1486_4852_7671_7342',
    'name': 'Regeneration',
    'type': 'dsiderNode',
    'position': {
      'x': 564.6389199048892,
      'y': -193.57475934875146
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'regeneration',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/genericdark.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/genericdark.svg',
      'componentDisplayName': 'Regeneration',
      'no_of_inputs': 2,
      'no_of_outputs': 1,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': 'demander',
      'input_node_arr': [1, 2],
      'output_node_arr': [1],
      'defaultType': 'default',
      'propertiesType': {
        'heating_efficiency': 'number',
        'initial_temp': 'number',
        'regeneration_capex_schedule': 'text',
        'regeneration_op_costs_schedule': 'text',
        'regeneration_period_costs_schedule': 'text'
      },
      'units': {
        'heating_efficiency': '%',
        'initial_temp': 'C (Celsius)',
        'regeneration_capex_schedule': '$/ton',
        'regeneration_op_costs_schedule': '$/ton',
        'regeneration_period_costs_schedule': '$/ton/year'
      },
      'properties': {
        'Capital Expense ($/mt)': '120',
        'Heating Efficiency (%)': '85',
        'Initial Temperature (C (Celsius))': '60',
        'Operation Cost ($/mt)': '0.2',
        'Period Cost ($/mt/year)': '35'
      },
      'formulaTitle': {
        'Capex': 'regeneration_capex_schedule',
        'Heating Efficiency': 'heating_efficiency',
        'Initial Temperature': 'initial_temp',
        'Operational Costs': 'regeneration_op_costs_schedule',
        'Period Cost': 'regeneration_period_costs_schedule'
      },
      'propertiesValues': {
        'regeneration_capex_schedule': defaultCapexScheduleData('120'),
        'heating_efficiency': '85',
        'initial_temp': '60',
        'regeneration_op_costs_schedule': scheduleDataForHydrogenDemand('0.2'),
        'regeneration_period_costs_schedule': defaultPeriodCostScheduleData('35')
      },
      'regeneration_capex_schedule': '120',
      'heating_efficiency': '85',
      'initial_temp': '60',
      'regeneration_op_costs_schedule': '0.2',
      'regeneration_period_costs_schedule': '35',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 564.6389199048892,
      'y': -193.57475934875146
    },
    'hidden': false
  }
};

export const CCUS_SOURCES_EDGES: Record<string, Edge[]> = {
  'flue_gas_supplier': [
    {
      'source': 'Flue_Gas_2235_4992_2513_1733_7957',
      'sourceHandle': 'output-1-Flue_Gas_2235_4992_2513_1733_7957',
      'target': 'Flue_Gas_Load_Balancer_204_8715_1737_7486_266',
      'targetHandle': 'input-2-Flue_Gas_Load_Balancer_204_8715_1737_7486_266',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Flue_Gas_2235_4992_2513_1733_7957output-1-Flue_Gas_2235_4992_2513_1733_7957-Flue_Gas_Load_Balancer_204_8715_1737_7486_266input-2-Flue_Gas_Load_Balancer_204_8715_1737_7486_266'
    }
  ],
  'mitico': [
    {
      'source': 'Mitico_727_3600_6389_797_1018',
      'sourceHandle': 'output-1-Mitico_727_3600_6389_797_1018',
      'target': 'CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'targetHandle': 'input-2-CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'selected': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Mitico_727_3600_6389_797_1018output-1-Mitico_727_3600_6389_797_1018-CO2_Load_Balancer_3758_4605_6120_9407_1338input-2-CO2_Load_Balancer_3758_4605_6120_9407_1338'
    },
    {
      'source': 'Flue_Gas_Load_Balancer_204_8715_1737_7486_266',
      'sourceHandle': 'output-1-Flue_Gas_Load_Balancer_204_8715_1737_7486_266',
      'target': 'Mitico_727_3600_6389_797_1018',
      'targetHandle': 'input-1-Mitico_727_3600_6389_797_1018',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'selected': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Flue_Gas_Load_Balancer_204_8715_1737_7486_266output-1-Flue_Gas_Load_Balancer_204_8715_1737_7486_266-Mitico_727_3600_6389_797_1018input-1-Mitico_727_3600_6389_797_1018'
    },
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-2-Electricity_LB_2698',
      'target': 'Mitico_727_3600_6389_797_1018',
      'targetHandle': 'input-2-Mitico_727_3600_6389_797_1018',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'selected': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Electricity_LB_2698-2-Electricity_LB_2698-Mitico_727_3600_6389_797_1018input-2-Mitico_727_3600_6389_797_1018'
    }
  ],
  'mitico1': [
    {
      'source': 'Mitico_7271808-copy_2960_1637_8217_7419',
      'sourceHandle': 'output-1-Mitico_7271808-copy_2960_1637_8217_7419',
      'target': 'CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'targetHandle': 'input-3-CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Mitico_7271808-copy_2960_1637_8217_7419output-1-Mitico_7271808-copy_2960_1637_8217_7419-CO2_Load_Balancer_3758_4605_6120_9407_1338input-3-CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-3-Electricity_LB_2698',
      'target': 'Mitico_7271808-copy_2960_1637_8217_7419',
      'targetHandle': 'input-3-Mitico_7271808-copy_2960_1637_8217_7419',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Electricity_LB_2698-3-Electricity_LB_6762_8876_6146_5407_1503-Mitico_7271808-copy_2960_1637_8217_7419input-3-Mitico_7271808-copy_2960_1637_8217_7419'
    }
  ],
  'natural_gas_supplier': [
    {
      'source': 'Natural_Gas_8486_8416_2951_9910_9423',
      'sourceHandle': 'output-1-Natural_Gas_8486_8416_2951_9910_9423',
      'target': 'Mitico_727_3600_6389_797_1018',
      'targetHandle': 'input-3-Mitico_727_3600_6389_797_1018',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Natural_Gas_8486_8416_2951_9910_9423output-1-Natural_Gas_8486_8416_2951_9910_9423-Mitico_727_3600_6389_797_1018input-3-Mitico_727_3600_6389_797_1018',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Natural_Gas_8486_8416_2951_9910_9423',
      'sourceHandle': 'output-2-Natural_Gas_8486_8416_2951_9910_9423',
      'target': 'Mitico_7271808-copy_2960_1637_8217_7419',
      'targetHandle': 'input-1-Mitico_7271808-copy_2960_1637_8217_7419',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Natural_Gas_8486_8416_2951_9910_9423output-2-Natural_Gas_8486_8416_2951_9910_9423-Mitico_7271808-copy_2960_1637_8217_7419input-1-Mitico_7271808-copy_2960_1637_8217_7419',
      'hidden': false,
      'selected': false
    }
  ],
  'liquefaction': [
    {
      'source': 'Liquefaction_4403_7326_7166_4073_4890',
      'sourceHandle': 'output-1-Liquefaction_4403_7326_7166_4073_4890',
      'target': 'Sequestration_6954_3582',
      'targetHandle': 'input-1-Sequestration_6954_3582',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Liquefaction_4403_7326_7166_4073_4890output-1-Liquefaction_4403_7326_7166_4073_4890-Sequestration_6954_3582input-1-Sequestration_6954_3582',
      'hidden': false
    },
    {
      'source': 'CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'sourceHandle': 'output-3-CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'target': 'Liquefaction_4403_7326_7166_4073_4890',
      'targetHandle': 'input-1-Liquefaction_4403_7326_7166_4073_4890',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'selected': false,
      'hidden': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-CO2_Load_Balancer_3758_4605_6120_9407_1338output-3-CO2_Load_Balancer_3758_4605_6120_9407_1338-Liquefaction_4403_7326_7166_4073_4890input-1-Liquefaction_4403_7326_7166_4073_4890'
    }
  ],
  'gaseous_co2_demander': [
    {
      'source': 'CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'sourceHandle': 'output-2-CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'target': 'CO2_Demand_293_8695_5094_212',
      'targetHandle': 'input-1-CO2_Demand_293_8695_5094_212',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-CO2_Load_Balancer_3758_4605_6120_9407_1338output-2-CO2_Load_Balancer_3758_4605_6120_9407_1338-CO2_Demand_293_8695_5094_212input-1-CO2_Demand_293_8695_5094_212',
      'hidden': false,
      'selected': false
    }
  ],
  'dac_fan': [
    {
      'source': 'DAC_Fan_3682_1735_5474_5422_9689',
      'sourceHandle': 'output-1-DAC_Fan_3682_1735_5474_5422_9689',
      'target': 'Process_Pump_2828_6109_4668_4711_3777',
      'targetHandle': 'input-1-Process_Pump_2828_6109_4668_4711_3777',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-DAC_Fan_3682_1735_5474_5422_9689output-1-DAC_Fan_3682_1735_5474_5422_9689-Process_Pump_2828_6109_4668_4711_3777input-1-Process_Pump_2828_6109_4668_4711_3777',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-2-Electricity_LB_2698',
      'target': 'DAC_Fan_3682_1735_5474_5422_9689',
      'targetHandle': 'input-1-DAC_Fan_3682_1735_5474_5422_9689',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Electricity_LB_2698output-2-Electricity_LB_2698-Mitico_7271808-DAC_Fan_3682_1735_5474_5422_9689input-1-DAC_Fan_3682_1735_5474_5422_9689',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'DAC_Fan_3682_1735_5474_5422_9689',
      'sourceHandle': 'output-2-DAC_Fan_3682_1735_5474_5422_9689',
      'target': 'Tower_9087_1426_5925_8447_8359',
      'targetHandle': 'input-1-Tower_9087_1426_5925_8447_8359',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-DAC_Fan_3682_1735_5474_5422_9689output-2-DAC_Fan_3682_1735_5474_5422_9689-Tower_9087_1426_5925_8447_8359input-1-Tower_9087_1426_5925_8447_8359input',
      'hidden': false,
      'selected': false
    }
  ],
  'process_pump': [
    {
      'source': 'Process_Pump_2828_6109_4668_4711_3777',
      'sourceHandle': 'output-2-Process_Pump_2828_6109_4668_4711_3777',
      'target': 'Transfer_Pump_1654_6463_8678_6551_6956',
      'targetHandle': 'input-1-Transfer_Pump_1654_6463_8678_6551_6956',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Process_Pump_2828_6109_4668_4711_3777output-2-Process_Pump_2828_6109_4668_4711_3777-Transfer_Pump_1654_6463_8678_6551_6956input-1-Transfer_Pump_1654_6463_8678_6551_6956',
      'hidden': false,
      'selected': false
    }
  ],
  'regeneration': [
    {
      'source': 'Tower_9087_1426_5925_8447_8359',
      'sourceHandle': 'output-1-Tower_9087_1426_5925_8447_8359',
      'target': 'Regeneration_4828_1486_4852_7671_7342',
      'targetHandle': 'input-1-Regeneration_4828_1486_4852_7671_7342',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Tower_9087_1426_5925_8447_8359output-1-Tower_9087_1426_5925_8447_8359-Regeneration_4828_1486_4852_7671_7342input-1-Regeneration_4828_1486_4852_7671_7342',
      'hidden': false,
      'selected': false
    }, {
      'source': 'Transfer_Pump_1654_6463_8678_6551_6956',
      'sourceHandle': 'output-1-Transfer_Pump_1654_6463_8678_6551_6956',
      'target': 'Regeneration_4828_1486_4852_7671_7342',
      'targetHandle': 'input-2-Regeneration_4828_1486_4852_7671_7342',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Transfer_Pump_1654_6463_8678_6551_6956output-1-Transfer_Pump_1654_6463_8678_6551_6956-Regeneration_4828_1486_4852_7671_7342input-2-Regeneration_4828_1486_4852_7671_7342',
      'hidden': false,
      'selected': false
    }, {
      'source': 'Regeneration_4828_1486_4852_7671_7342',
      'sourceHandle': 'output-1-Regeneration_4828_1486_4852_7671_7342',
      'target': 'CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'targetHandle': 'input-2-CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Regeneration_4828_1486_4852_7671_7342output-1-Regeneration_4828_1486_4852_7671_7342-CO2_Load_Balancer_3758_4605_6120_9407_1338input-2-CO2_Load_Balancer_3758_4605_6120_9407_1338',
      'hidden': false,
      'selected': false
    }
  ]
};

export const BIOFUEL_SOURCES: Record<string, IDsiderNode> = {
  'biomass_supplier': {
    'width': 64,
    'height': 64,
    'id': 'Biomass_Supplier_9050_3729',
    'name': 'Biomass Supplier',
    'type': 'dsiderNode',
    'position': {
      'x': -120.43828958499569,
      'y': -301.7464597453518
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'biomass_supplier',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Generic+Supply.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Generic+Supply.svg',
      'componentDisplayName': 'Biomass Supplier',
      'no_of_inputs': 2,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Biomass Supplier Capacity',
      'capacityProperty': ['flow_rate'],
      'powerCategory': 'supplier',
      'input_node_arr': [1, 2],
      'output_node_arr': [
        1, 2
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'Carbon_Content': 'number',
        'Hydrogen_Content': 'number',
        'Moisture_Content': 'number',
        'Oxygen_Content': 'number',
        'flow_rate': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'Carbon_Content': '%',
        'flow_rate': 'Nm^3/hr',
        'Hydrogen_Content': '%',
        'Moisture_Content': '%',
        'Oxygen_Content': '%',
        'scope_1_opex': 'kg CO2e/Nm^3',
        'scope_2_opex': 'kg CO2e/Nm^3',
        'scope_3_capex': 'kg CO2e/Nm^3',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Carbon Content (%)': 30,
        'Flow rate (Nm^3/hr)': 1000,
        'Hydrogen Content (%)': 60,
        'Moisture Content (%)': 5,
        'Oxygen Content (%)': 5,
        'Scope 1 Intensity (kg CO2e/Nm^3)': 0,
        'Scope 2 Intensity (kg CO2e/Nm^3)': 0.3707,
        'Scope 3 Emissions (kg CO2e/Nm^3)': 0,
        'Transmission loss proportion (Percentage (%)': 5
      },
      'formulaTitle': {
        'Location': 'location',
        'Carbon Content': 'Carbon_Content',
        'Flow rate': 'flow_rate',
        'Hydrogen Content': 'Hydrogen_Content',
        'Moisture Content': 'Moisture_Content',
        'Oxygen Content': 'Oxygen_Content',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Intensity': 'scope_3_opex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'Carbon_Content': 30,
        'Hydrogen_Content': 60,
        'Moisture_Content': 5,
        'Oxygen_Content': 5,
        'flow_rate': 1000,
        'scope_1_opex': 0,
        'scope_2_opex': 0.3707,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 5
      },
      'location': '29.749907, -95.358421',
      'Carbon_Content': 30,
      'Hydrogen_Content': 60,
      'Moisture_Content': 5,
      'Oxygen_Content': 5,
      'flow_rate': 1000,
      'scope_1_opex': 0,
      'scope_2_opex': 0.3707,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 5,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': -120.43828958499569,
      'y': -301.7464597453518
    },
    'hidden': false
  },
  'gasifier': {
    'width': 64,
    'height': 64,
    'id': 'Gasifier_1270_4808',
    'name': 'Gasifier',
    'type': 'dsiderNode',
    'position': {
      'x': 115.93787344961913,
      'y': -224.6956662699801
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'gasifier',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Generic+Reactor.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Generic+Reactor.svg',
      'componentDisplayName': 'Gasifier',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Gasifier Capacity',
      'capacityProperty': ['power_capacity'],
      'powerCategory': 'reactor',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'capex_schedule': 'text',
        'efficiency_proportion': 'number',
        'max_volumetric_capacity': 'number',
        'op_costs_schedule': 'text',
        'period_costs_schedule': 'text',
        'power_capacity': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'capex_schedule': '$/m^3',
        'efficiency_proportion': '%',
        'max_volumetric_capacity': 'm^3/hr',
        'op_costs_schedule': '$/m^3',
        'period_costs_schedule': '$/year',
        'power_capacity': 'kWh',
        'scope_1_opex': 'kg CO2e',
        'scope_2_opex': 'kg CO2e',
        'scope_3_capex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Capital Cost ($/m^3)': 3.42,
        'Efficiency Proportion (%)': 100,
        'Max Volumetric Capacity (m^3/hr)': 1000,
        'Non-energy Operating Cost ($/mt)': 21.08,
        'Period Cost ($/year)': 98,
        'Power Capacity (kWh)': 1000,
        'Scope 1 Intensity (kg CO2e)': 0,
        'Scope 2 Intensity (kg CO2e)': 0,
        'Scope 3 Intensity (kg CO2)': 0,
        'Transmission loss proportion (Percentage (%))': 0.108
      },
      'formulaTitle': {
        'Location': 'location',
        'Capital Cost': 'capex_schedule',
        'Max Volumetric Capacity': 'max_volumetric_capacity',
        'Efficiency Proportion': 'efficiency_proportion',
        'Operation Cost': 'op_costs_schedule',
        'Period Cost': 'period_costs_schedule',
        'Power Capacity': 'power_capacity',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'efficiency_proportion': 100,
        'op_costs_schedule': defaultOpexData('21.08', '21.08'),
        'period_costs_schedule': defaultPeriodCostScheduleData('98'),
        'power_capacity': 1000,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'max_volumetric_capacity': 1000,
        'capex_schedule': defaultCapexScheduleData('3.42'),
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'capex_schedule': 0,
      'power_capacity': 1000,
      'transmission_loss_proportion': 0,
      'max_volumetric_capacity': 1000,
      'efficiency_proportion': 100,
      'period_costs_schedule': 98,
      'op_costs_schedule': 21.08,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 115.93787344961913,
      'y': -224.6956662699801
    },
    'hidden': false
  },
  'wgs': {
    'width': 64,
    'height': 64,
    'id': 'WGS_8700_9239',
    'name': 'WGS',
    'type': 'dsiderNode',
    'position': {
      'x': 298.77026474711124,
      'y': -224.69566626998005
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'wgs',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/SMR.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/SMR.svg',
      'componentDisplayName': 'WGS',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'WGS Capacity',
      'capacityProperty': ['power_capacity'],
      'powerCategory': 'reactor',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'capex_schedule': 'text',
        'max_ch4_allowed': 'number',
        'max_h2o_allowed': 'number',
        'minimum_required_ch4': 'number',
        'minimum_required_h2o': 'number',
        'op_costs_schedule': 'text',
        'output_gas_ch4_proportion': 'number',
        'output_gas_co2_proportion': 'number',
        'output_gas_co_proportion': 'number',
        'output_gas_h2_proportion': 'number',
        'output_gas_h2o_proportion': 'number',
        'period_costs_schedule': 'text',
        'power_capacity': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'max_ch4_allowed': 'Nm^3/hr',
        'max_h2o_allowed': 'Nm^3/hr',
        'minimum_required_ch4': 'Nm^3/hr',
        'minimum_required_h2o': 'Nm^3/hr',
        'output_gas_ch4_proportion': 'Percentage (%)',
        'output_gas_co2_proportion': 'Percentage (%)',
        'output_gas_co_proportion': 'Percentage (%)',
        'op_costs_schedule': '$/mt',
        'output_gas_h2_proportion': 'Percentage (%)',
        'output_gas_h2o_proportion': 'Percentage (%)',
        'capex_schedule': '$',
        'period_costs_schedule': '$/mt/year',
        'power_capacity': 'kWh/mt',
        'scope_1_opex': 'kg CO2e/kg CO2',
        'scope_2_opex': 'kg CO2e/kg CO2',
        'scope_3_capex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Max CH4 Allowed (Nm^3/hr)': 100000,
        'Max H2O Allowed (Nm^3/hr)': 100000,
        'Minimum Required CH4 (Nm^3/hr)': 0,
        'Minimum Required H2O (Nm^3/hr)': 0,
        'Operation Cost ($/mt)': 10,
        'Output Gas CH4 Proportion (Percentage (%))': 0.05,
        'Output Gas CO2 Proportion (Percentage (%))': 0.15,
        'Output Gas CO Proportion (Percentage (%))': 0.15,
        'Output Gas H2 Proportion (Percentage (%))': 0.75,
        'Output Gas H2O Proportion (Percentage (%))': 0.05,
        'Period Cost ($/mt/year)': 100,
        'Power Capacity (kWh/mt)': 1000,
        'Scope 1 Intensity (kg CO2e/kg CO2)': 1,
        'Scope 2 Intensity (kg CO2e/kg CO2)': 1,
        'Scope 3 Emissions (kg CO2e)': 0.4,
        'Transmission loss proportion (Percentage (%)': 0,
        'Capital Cost ($)': 3.42
      },
      'formulaTitle': {
        'Location': 'location',
        'Max CH4 Allowed': 'max_ch4_allowed',
        'Max H2O Allowed': 'max_h2o_allowed',
        'Minimum Required CH4': 'minimum_required_ch4',
        'Minimum Required H2O': 'minimum_required_h2o',
        'Operation Cost': 'op_costs_schedule',
        'Output Gas CH4 Proportion': 'output_gas_ch4_proportion',
        'Output Gas CO2 Proportion': 'output_gas_co2_proportion',
        'Output Gas CO Proportion': 'output_gas_co_proportion',
        'Output Gas H2 Proportion': 'output_gas_h2_proportion',
        'Output Gas H2O Proportion': 'output_gas_h2o_proportion',
        'Period Cost': 'period_costs_schedule',
        'Power Capacity': 'power_capacity',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion',
        'Capital Cost': 'capex_schedule'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'max_ch4_allowed': 100000,
        'max_h2o_allowed': 100000,
        'minimum_required_ch4': 0,
        'minimum_required_h2o': 0,
        'output_gas_ch4_proportion': 0.05,
        'output_gas_co2_proportion': 0.15,
        'output_gas_co_proportion': 0.15,
        'op_costs_schedule': defaultOpexData('10', '10'),
        'period_costs_schedule': defaultPeriodCostScheduleData('100'),
        'output_gas_h2_proportion': 0.75,
        'output_gas_h2o_proportion': 0.05,
        'power_capacity': 1000,
        'transmission_loss_proportion': 0,
        'scope_1_opex': 1,
        'scope_2_opex': 1,
        'scope_3_capex': 0.4,
        'capex_schedule': defaultCapexScheduleData('3.42')
      },
      'location': '29.749907, -95.358421',
      'max_ch4_allowed': 100000,
      'max_h2o_allowed': 100000,
      'minimum_required_ch4': 0,
      'minimum_required_h2o': 0,
      'output_gas_ch4_proportion': 0.05,
      'output_gas_co2_proportion': 0.15,
      'output_gas_co_proportion': 0.15,
      'op_costs_schedule': 10,
      'period_costs_schedule': 100,
      'output_gas_h2_proportion': 0.75,
      'output_gas_h2o_proportion': 0.05,
      'power_capacity': 1000,
      'transmission_loss_proportion': 0,
      'scope_1_opex': 1,
      'scope_2_opex': 1,
      'scope_3_capex': 0.4,
      'capex_schedule': 3.42,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 298.77026474711124,
      'y': -224.69566626998005
    },
    'hidden': false
  },
  'ft_reactor': {
    'width': 64,
    'height': 64,
    'id': 'FT_Reactor_8022_2461',
    'name': 'FT Reactor',
    'type': 'dsiderNode',
    'position': {
      'x': 475.0729277839787,
      'y': -224.69566626998008
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'ft_reactor',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Generic+Reactor.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Generic+Reactor.svg',
      'componentDisplayName': 'FT Reactor',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'FT Reactor Capacity',
      'capacityProperty': ['power_capacity'],
      'powerCategory': 'reactor',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'capex_schedule': 'text',
        'efficiency_proportion': 'number',
        'max_volumetric_capacity': 'number',
        'op_costs_schedule': 'text',
        'period_costs_schedule': 'text',
        'power_capacity': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'capex_schedule': '$/m^3',
        'efficiency_proportion': '%',
        'max_volumetric_capacity': 'm^3/hr',
        'op_costs_schedule': '$/m^3',
        'period_costs_schedule': '$/m^3/year',
        'power_capacity': 'kWh',
        'scope_1_opex': 'kg CO2e',
        'scope_2_opex': 'kg CO2e',
        'scope_3_capex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Capital Cost ($/m^3)': 3.42,
        'Efficiency Proportion (%)': 100,
        'Max Volumetric Capacity (m^3/hr)': 1000,
        'Period Cost ($/m^3/year)': 100,
        'Power Capacity (kWh)': 1000,
        'Scope 1 Intensity (kg CO2e)': 0.0001,
        'Scope 2 Intensity (kg CO2e)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Transmission loss proportion (Percentage (%))': 0,
        'Operation Cost ($/m^3)': 10
      },
      'formulaTitle': {
        'Location': 'location',
        'Capital Cost': 'capex_schedule',
        'Efficiency Proportion': 'efficiency_proportion',
        'Max Volumetric Capacity': 'max_volumetric_capacity',
        'Operation Cost': 'op_costs_schedule',
        'Period Cost': 'period_costs_schedule',
        'Power Capacity': 'power_capacity',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'capex_schedule': defaultCapexScheduleData('3.42'),
        'efficiency_proportion': 100,
        'max_volumetric_capacity': 1000,
        'op_costs_schedule': defaultOpexData('10', '10'),
        'period_costs_schedule': defaultPeriodCostScheduleData('100'),
        'power_capacity': 1000,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'capex_schedule': 3.42,
      'efficiency_proportion': 100,
      'max_volumetric_capacity': 1000,
      'op_costs_schedule': 10,
      'period_costs_schedule': 100,
      'power_capacity': 1000,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 475.0729277839787,
      'y': -224.69566626998008
    },
    'hidden': false
  },
  'hydroprocessing': {
    'width': 64,
    'height': 64,
    'id': 'Hydroprocessing_6198_2269',
    'name': 'Hydroprocessing',
    'type': 'dsiderNode',
    'position': {
      'x': 635.704242995347,
      'y': -224.6956662699801
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydroprocessing',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Generic+Reactor.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Generic+Reactor.svg',
      'componentDisplayName': 'Hydroprocessing',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Hydroprocessing Capacity',
      'capacityProperty': ['power_capacity'],
      'powerCategory': 'reactor',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'capex_schedule': 'text',
        'efficiency_proportion': 'number',
        'max_volumetric_capacity': 'number',
        'op_costs_schedule': 'text',
        'period_costs_schedule': 'text',
        'power_capacity': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'capex_schedule': '$/m^3',
        'efficiency_proportion': '%',
        'max_volumetric_capacity': 'm^3/hr',
        'op_costs_schedule': '$/m^3',
        'period_costs_schedule': '$/year',
        'power_capacity': 'kWh',
        'scope_1_opex': 'kg CO2e',
        'scope_2_opex': 'kg CO2e',
        'scope_3_capex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Capital Cost ($/m^3)': 3.42,
        'Efficiency Proportion (%)': 100,
        'Max Volumetric Capacity (m^3/hr)': 1000,
        'Period Cost ($/year)': 98,
        'Power Capacity (kWh)': 1000,
        'Scope 1 Intensity (kg CO2e)': 0,
        'Scope 2 Intensity (kg CO2e)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Transmission loss proportion (Percentage (%))': 0,
        'Operation Cost ($/m^3)': 10
      },
      'formulaTitle': {
        'Location': 'location',
        'Capital Cost': 'capex_schedule',
        'Efficiency Proportion': 'efficiency_proportion',
        'Max Volumetric Capacity': 'max_volumetric_capacity',
        'Operation Cost': 'op_costs_schedule',
        'Period Cost': 'period_costs_schedule',
        'Power Capacity': 'power_capacity',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'capex_schedule': defaultCapexScheduleData('3.42'),
        'efficiency_proportion': 100,
        'max_volumetric_capacity': 1000,
        'op_costs_schedule': defaultOpexData('10', '10'),
        'period_costs_schedule': defaultPeriodCostScheduleData('98'),
        'power_capacity': 1000,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'capex_schedule': 3.42,
      'efficiency_proportion': 100,
      'max_volumetric_capacity': 1000,
      'op_costs_schedule': 10,
      'period_costs_schedule': 98,
      'power_capacity': 1000,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 635.704242995347,
      'y': -224.6956662699801
    },
    'hidden': false
  },
  'hydrogenation': {
    'width': 64,
    'height': 64,
    'id': 'Hydrogenation_6415',
    'name': 'Hydrogenation',
    'type': 'dsiderNode',
    'position': {
      'x': 505,
      'y': 218
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogenation',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/boiler.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/boiler.svg',
      'componentDisplayName': 'Hydrogenation',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Hydrogenation Capacity',
      'capacityProperty': ['power_capacity'],
      'powerCategory': 'reactor',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'capex_schedule': 'text',
        'efficiency_proportion': 'number',
        'max_volumetric_capacity': 'number',
        'op_costs_schedule': 'text',
        'period_costs_schedule': 'text',
        'power_capacity': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'capex_schedule': '$/m^3',
        'efficiency_proportion': '%',
        'max_volumetric_capacity': 'm^3/hr',
        'op_costs_schedule': '$/m^3',
        'period_costs_schedule': '$/year',
        'power_capacity': 'kWh',
        'scope_1_opex': 'kg CO2e',
        'scope_2_opex': 'kg CO2e',
        'scope_3_capex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Capital Cost ($/m^3)': 3.42,
        'Efficiency Proportion (%)': 100,
        'Max Volumetric Capacity (m^3/hr)': 1000,
        'Period Cost ($/year)': 98,
        'Power Capacity (kWh)': 1000,
        'Scope 1 Intensity (kg CO2e)': 0,
        'Scope 2 Intensity (kg CO2e)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Transmission loss proportion (Percentage (%))': 0,
        'Operation Cost ($/m^3)': 10
      },
      'formulaTitle': {
        'Location': 'location',
        'Capital Cost': 'capex_schedule',
        'Efficiency Proportion': 'efficiency_proportion',
        'Max Volumetric Capacity': 'max_volumetric_capacity',
        'Operation Cost': 'op_costs_schedule',
        'Period Cost': 'period_costs_schedule',
        'Power Capacity': 'power_capacity',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'capex_schedule': defaultCapexScheduleData('3.42'),
        'efficiency_proportion': 100,
        'max_volumetric_capacity': 1000,
        'op_costs_schedule': defaultOpexData('10', '10'),
        'period_costs_schedule': defaultPeriodCostScheduleData('98'),
        'power_capacity': 1000,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'capex_schedule': 3.42,
      'efficiency_proportion': 100,
      'max_volumetric_capacity': 1000,
      'op_costs_schedule': 10,
      'period_costs_schedule': 98,
      'power_capacity': 1000,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 505,
      'y': 218
    },
    'hidden': false
  },
  'steam_supplier': {
    'width': 64,
    'height': 64,
    'id': 'Steam_supplier_4547_2208',
    'name': 'Steam supplier',
    'type': 'dsiderNode',
    'position': {
      'x': 125.07949301449375,
      'y': -378.79725322072346
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'steam_supplier',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Steam+Supplier.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Steam+Supplier.svg',
      'componentDisplayName': 'Steam supplier',
      'no_of_inputs': 2,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Steam supplier Capacity',
      'capacityProperty': ['flow_rate'],
      'powerCategory': 'supplier',
      'input_node_arr': [1, 2],
      'output_node_arr': [
        1, 2
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'flow_rate': 'number',
        'gas_concentrations': 'number',
        'operational_cost_schedule': 'text',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'flow_rate': 'Nm^3/hr',
        'gas_concentrations': 'vol%',
        'location': '',
        'operational_cost_schedule': '$/Nm^3',
        'scope_1_opex': 'kg CO2e/Nm^3',
        'scope_2_opex': 'kg CO2e/Nm^3',
        'scope_3_capex': 'kg CO2e/Nm^3',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Flow rate (Nm^3/hr)': 10,
        'Gas Concentration (vol%)': 1,
        'Location': '30.24, -97.74',
        'Price ($/Nm^3)': 0,
        'Scope 1 Intensity (kg CO2e/Nm^3)': 0,
        'Scope 2 Intensity (kg CO2e/Nm^3)': 0.3707,
        'Scope 3 Emissions (kg CO2e/Nm^3)': 0,
        'Transmission loss proportion (Percentage (%))': 5
      },
      'formulaTitle': {
        'Flow rate': 'flow_rate',
        'Gas Concentration': 'gas_concentrations',
        'Location': 'location',
        'Price': 'operational_cost_schedule',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'flow_rate': 10,
        'gas_concentrations': 1,
        'location': '30.24, -97.74',
        'operational_cost_schedule': defaultOpexData('0', '0'),
        'scope_1_opex': 0,
        'scope_2_opex': 0.3707,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 5
      },
      'flow_rate': 10,
      'gas_concentrations': 1,
      'operational_cost_schedule': 0,
      'scope_1_opex': 0,
      'scope_2_opex': 0.3707,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 5,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 125.07949301449375,
      'y': -378.79725322072346
    },
    'hidden': false
  },
  'hydrogen_supplier': {
    'width': 64,
    'height': 64,
    'id': 'Hydrogen_Supplier_3412_7632',
    'name': 'Hydrogen Supplier',
    'type': 'dsiderNode',
    'position': {
      'x': 468.5431995233539,
      'y': -376.18536191647354
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogen_supplier',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/External+Gray+Supply.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/External+Gray+Supply.svg',
      'componentDisplayName': 'Hydrogen Supplier',
      'no_of_inputs': 2,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Liquefaction',
      'capacityProperty': 'gas_capacity',
      'powerCategory': 'reactor',
      'input_node_arr': [1, 2],
      'output_node_arr': [
        1, 2
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'flow_rate': 'number',
        'price': 'text',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'flow_rate': 'Nm^3/hr',
        'price': '$/m^3',
        'scope_1_opex': 'CO2e/m^3',
        'scope_2_opex': 'CO2e/m^3',
        'scope_3_capex': 'CO2e/m^3',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Flow Rate (Nm^3/hr)': 1000,
        'Price ($/m^3)': 0.089,
        'Scope 1 Intensity (CO2e/m^3)': 0,
        'Scope 2 Intensity (CO2e/m^3)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Transmission loss proportion (Percentage (%))': 0
      },
      'formulaTitle': {
        'Location': 'location',
        'Flow Rate': 'flow_rate',
        'Price': 'price',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'flow_rate': 1000,
        'op_costs_schedule': scheduleDataForHydrogenDemand('0.089'),
        'power_capacity': 5,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'flow_rate': 1000,
      'op_costs_schedule': 0.089,
      'power_capacity': 5,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 468.5431995233539,
      'y': -376.18536191647354
    },
    'hidden': false
  },
  'methanol_demander': {
    'width': 64,
    'height': 64,
    'id': 'Methanol_Demander_111',
    'name': 'Methanol Demander',
    'type': 'dsiderNode',
    'position': {
      'x': 801.5593408152147,
      'y': -224.69566626998008
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'methanol_demander',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Generic+Demand.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Generic+Demand.svg',
      'componentDisplayName': 'Methanol Demander',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'Methanol Demander Capacity',
      'capacityProperty': ['demand_schedule'],
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'Methanol_Demand': 'number',
        'Selling_price_of_Methanol': 'number'
      },
      'units': {
        'location': '',
        'Methanol_Demand': 'Nm^3/hr',
        'Selling_price_of_Methanol': '$/Nm'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Methanol Demand (Nm^3/hr)': 1000,
        'Selling price of Methanol ($/Nm^3)': 706.8
      },
      'formulaTitle': {
        'Location': 'location',
        'Methanol Demand': 'Methanol_Demand',
        'Selling price of Methanol': 'Selling_price_of_Methanol'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'Methanol_Demand': 1000,
        'Selling_price_of_Methanol': 706.8
      },
      'location': '29.749907, -95.358421',
      'Methanol_Demand': 1000,
      'Selling_price_of_Methanol': 706.8,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 801.5593408152147,
      'y': -224.69566626998008
    },
    'hidden': false
  },
  'rd_demander': {
    'width': 64,
    'height': 64,
    'id': 'RD_Demander_7542',
    'name': 'RD Demander',
    'type': 'dsiderNode',
    'position': {
      'x': 797.64150385884,
      'y': -224.69566626998008
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'rd_demander',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Generic+Demand.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Generic+Demand.svg',
      'componentDisplayName': 'RD Demander',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'RD Demander Capacity',
      'capacityProperty': ['demand_schedule'],
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'RD_Demand': 'number',
        'Selling_price_of_RD': 'number'
      },
      'units': {
        'location': '',
        'RD_Demand': 'Nm^3/hr',
        'Selling_price_of_RD': '$/Nm^3'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'RD Demand (Nm^3/hr)': 1000,
        'Selling price of RD ($/Nm^3)': 1424
      },
      'formulaTitle': {
        'Location': 'location',
        'RD Demand': 'RD_Demand',
        'Selling price of RD': 'Selling_price_of_RD'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'RD_Demand': 1000,
        'Selling_price_of_RD': 1424
      },
      'location': '29.749907, -95.358421',
      'RD_Demand': 1000,
      'Selling_price_of_RD': 1424,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 797.64150385884,
      'y': -224.69566626998008
    },
    'hidden': false
  },
  'saf_demander': {
    'width': 64,
    'height': 64,
    'id': 'SAF_Demander_8704',
    'name': 'SAF Demander',
    'type': 'dsiderNode',
    'position': {
      'x': 807.3114321508726,
      'y': -225.00633024809716
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'saf_demander',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Generic+Demand.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Generic+Demand.svg',
      'componentDisplayName': 'SAF Demander',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'SAF Demander Capacity',
      'capacityProperty': ['demand'],
      'powerCategory': 'demander',
      'input_node_arr': [1],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'demand_schedule': 'text',
        'price_schedule': 'text'
      },
      'units': {
        'location': '',
        'demand_schedule': 'Nm^3/hr',
        'price_schedule': '$/Nm^3'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Demand Schedule (Nm^3/hr)': 1000,
        'Price Schedule ($/Nm^3)': 1000
      },
      'formulaTitle': {
        'Location': 'location',
        'Demand Schedule': 'demand_schedule',
        'Price Schedule': 'price_schedule'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'demand_schedule': 1000,
        'price_schedule': 1000
      },
      'location': '29.749907, -95.358421',
      'demand_schedule': 1000,
      'price_schedule': 1000,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 807.3114321508726,
      'y': -225.00633024809716
    },
    'hidden': false
  },
  'pyrolysis': {
    'width': 64,
    'height': 64,
    'id': 'Pyrolysis_6572_2100',
    'name': 'Pyrolysis',
    'type': 'dsiderNode',
    'position': {
      'x': 351.40520658001185,
      'y': 200.148698354997
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'pyrolysis',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Natural+Gas+Gasifier.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Natural+Gas+Gasifier.svg',
      'componentDisplayName': 'Pyrolysis',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Pyrolysis Capacity',
      'capacityProperty': ['power_capacity'],
      'powerCategory': 'reactor',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'capex_schedule': 'text',
        'Bio-Char_proportion': 'number',
        'Bio-Oil_proportion': 'number',
        'op_costs_schedule': 'text',
        'period_costs_schedule': 'text',
        'power_capacity': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'capex_schedule': '$',
        'Bio-Char_proportion': '%',
        'Bio-Oil_proportion': '%',
        'op_costs_schedule': '$/mt',
        'period_costs_schedule': '$/mt/year',
        'power_capacity': 'kWh/mt',
        'scope_1_opex': 'kg CO2e/kg CO2',
        'scope_2_opex': 'kg CO2e/kg CO2',
        'scope_3_capex': 'kg CO2e',
        'transmission_loss_proportion': 'Percentage (%)'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Capital Cost ($)': 3.42,
        'Bio-Char proportion (%)': 40,
        'Bio-Oil proportion (%)': 40,
        'Period Cost ($/mt/year)': 98,
        'Power Capacity (kWh/mt)': 1000,
        'Scope 1 Intensity (kg CO2e/kg CO2)': 0,
        'Scope 2 Intensity (kg CO2e/kg CO2)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Transmission loss proportion (Percentage (%))': 0,
        'Operation Cost ($/mt)': 10
      },
      'formulaTitle': {
        'Location': 'location',
        'Capital Cost': 'capex_schedule',
        'Bio-Char proportion': 'Bio-Char_proportion',
        'Bio-Oil proportion': 'Bio-Oil_proportion',
        'Operation Cost': 'op_costs_schedule',
        'Period Cost': 'period_costs_schedule',
        'Power Capacity': 'power_capacity',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Transmission loss proportion': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'capex_schedule': defaultCapexScheduleData('3.42'),
        'Bio-Char_proportion': 40,
        'Bio-Oil proportion': 40,
        'op_costs_schedule': defaultOpexData('10', '10'),
        'period_costs_schedule': defaultPeriodCostScheduleData('98'),
        'power_capacity': 1000,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'capex_schedule': 3.42,
      'Bio-Char_proportion': 100,
      'Bio-Oil proportion': 1000,
      'op_costs_schedule': 10,
      'period_costs_schedule': 98,
      'power_capacity': 1000,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 351.40520658001185,
      'y': 200.148698354997
    },
    'hidden': false
  },
};

export const BIOFUEL_SOURCES_EDGES: Record<string, Edge[]> = {
  'gasifier': [
    {
      'source': 'Gasifier_1270_4808',
      'sourceHandle': 'output-1-Gasifier_1270_4808',
      'target': 'WGS_8700_9239',
      'targetHandle': 'input-1-WGS_8700_9239',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Gasifier_1270_4808output-1-Gasifier_1270_4808-WGS_8700_9239input-1-WGS_8700_9239'
    }, {
      'source': 'Biomass_Supplier_9050_3729',
      'sourceHandle': 'output-2-Biomass_Supplier_9050_3729',
      'target': 'Gasifier_1270_4808',
      'targetHandle': 'input-1-Gasifier_1270_4808',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Biomass_Supplier_9050_3729output-2-Biomass_Supplier_9050_3729-Gasifier_1270_4808input-1-Gasifier_1270_4808'
    }, {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-1-Electricity_LB_2698',
      'target': 'Gasifier_1270_4808',
      'targetHandle': 'input-1-Gasifier_1270_4808',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      // eslint-disable-next-line max-len
      'id': 'reactflow__edge-Electricity_LB_2698output-1-Electricity_LB_2698-Gasifier_1270_4808input-1-Gasifier_1270_4808'
    }
  ]
};
