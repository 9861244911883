import React from 'react';
import { QuestionListCard, TextElement } from '../../pages/wizard/WizardQuestionType';
import { Button } from 'react-bootstrap';
import { IDemandQuestionProps } from '../../pages/wizard/wizardInterface';
import { NUMBER } from '../../constants';

export const DemandSupplyQuestion = (porps: IDemandQuestionProps) => {
  const { que, index, answersList, setAnswersList, submitQuestion, keyToUpdate } = porps;

  const isElectricPowerDemand = que.mapping === 'Demand';
  const key = keyToUpdate ?? 'supplyDemand';

  const updateDemandSupply = (value: string) => {
    setAnswersList(() => ({
      ...answersList,
      [key]: +value
    }));
  };

  return (
    <div className="wizard-ques-main" key={`${que.question}-${index}`}>
      <QuestionListCard question={que.question} />
      <div className='wizard-ques-input-wrap'>
        {
          que.answers?.map((powerSource, ind) => (
            <TextElement defaultValue={answersList[key]} updateValue={updateDemandSupply} powerSource={powerSource}
              unit={isElectricPowerDemand ? 'Kwh' : powerSource} key={`ques-${ind}`} />
          ))
        }
        <Button disabled={!answersList[key]} className='shadow' onClick={() => submitQuestion(index + NUMBER.N1)}>Submit</Button>
      </div>
    </div>
  );
};
